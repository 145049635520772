import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { createContext } from "react";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = React.useState([]);

  const values = React.useMemo(
    () => ({ user, setUser }),
    [user, setUser]
  );
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

const SIGN_IN = gql`
  mutation Mutation($email: String!, $password: String) {
    Signin(email: $email, password: $password) {
      ok
      error
      payload {
        token
        user_id
        role
        accessToken
        refreshToken
        email
      }
    }
  }
`;

export const useAuth = () => {
  const { context, setContext } = React.useContext(UserContext);

  // const useSigin = useMutation(SIGN_IN, {
  //   update(cache, { data: { AddDisease } }) {
  //     // const existing = cache.readQuery({
  //     //   query: GET_DISEASE,
  //     //   variables: {},
  //     // });
  //     // const newData = [...existing.GetAllDiseases, AddDisease];
  //     // cache.writeQuery({
  //     //   query: GET_USER,
  //     //   variables: {},
  //     //   data: { GetAllDiseases: newData },
  //     // });
  //   },
  // });
  const useSignin = useMutation(SIGN_IN);


  const { user, setUser } = React.useContext(UserContext);
  // const useGetDatapoints = useLazyQuery(GET_USER);

  // const removeUser = (id) => {};

  return {
    useSignin,
    setUser
  };
};
