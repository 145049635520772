import DragIndicatorSharpIcon from "@mui/icons-material/DragIndicatorSharp";
import { colors } from "../constant";
export const ListItem = ({ data, index }) => {
  const getColor = (data, index) => {
    if (data.type === "section") {
      return colors.TEXT_GREY;
    } else {
      return index % 2 == 0 ? colors.LIGHT_BLUE : colors.WHITE;
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          backgroundColor: getColor(data, index),
          fontFamily: "Noto Sans",
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        <div
          style={{
            padding: "4px",
            cursor: "pointer",
            color: colors.LIGHT_GREY,
          }}
        >
          <DragIndicatorSharpIcon />
        </div>
        <div style={{ padding: "4px" }}>{data?.label}</div>
      </div>
    </>
  );
};
