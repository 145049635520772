import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { createContext } from "react";
const DiseaseAreaContext = createContext();
export const DiseaseAreaContextProvider = ({ children }) => {
  const [context, setContextData] = React.useState({});
  const setContext = React.useCallback((data) => {
    setContextData(data);
  }, []);
  const values = React.useMemo(
    () => ({ context, setContext }),
    [context, setContext]
  );

  return (
    <DiseaseAreaContext.Provider value={values}>
      {children}
    </DiseaseAreaContext.Provider>
  );
};

const ADD_PERSONA = gql`
  mutation AddPersona($title: String!, $score: [Int!]!, $diseaseUuid: String!) {
    AddPersona(title: $title, score: $score, disease_uuid: $diseaseUuid) {
      uuid
      title
      score
    }
  }
`;
const UPDATE_PERSONA = gql`
  mutation Mutation(
    $uuid: String!
    $title: String!
    $score: [Int!]!
    $diseaseUuid: String!
  ) {
    UpdatePersona(
      uuid: $uuid
      title: $title
      score: $score
      disease_uuid: $diseaseUuid
    ) {
      uuid
      title
      score
    }
  }
`;
const DELETE_PERSONA = gql`
  mutation Mutation($uuid: String!) {
    DeletePersona(uuid: $uuid)
  }
`;
const GET_PERSONA = gql`
  query Query($diseaseUuid: String!) {
    GetAllPersonas(disease_uuid: $diseaseUuid) {
      uuid
      title
      score
    }
  }
`;
const ADD_DATAPOINT = gql`
  mutation AddDataPoint(
    $diseaseUuid: String!
    $key: String!
    $required: Boolean!
    $type: String!
    $datapointType: DatapointType
    $source: SourceType
    $Id: Int
    $label: String
    $uuid: String
    $values: JSON
    $scores: JSON
    $min: Int
    $max: Int
    $next: String
  ) {
    AddDataPoint(
      disease_uuid: $diseaseUuid
      key: $key
      required: $required
      type: $type
      datapoint_type: $datapointType
      source: $source
      id: $Id
      label: $label
      uuid: $uuid
      values: $values
      scores: $scores
      min: $min
      max: $max
      next: $next
    ) {
      disease_uuid
      uuid
      label
      key
      required
      type
      values
      scores
      min
      max
      next
      id
      source
      datapoint_type
    }
  }
`;
const GET_DATAPOINT = gql`
  query Query($diseaseUuid: String!, $datapointType: String, $source: String) {
    GetAllDataPoints(
      disease_uuid: $diseaseUuid
      datapoint_type: $datapointType
      source: $source
    ) {
      disease_uuid
      uuid
      label
      key
      required
      type
      values
      scores
      min
      max
      next
      id
      datapoint_type
      source
    }
  }
`;
const GET_DATAPOINT_OPTIONS = gql`
  query Query(
    $diseaseUuid: String!
    $datapointType: String
    $source: String
    $type: String
  ) {
    DataPointOption: GetAllDataPoints(
      disease_uuid: $diseaseUuid
      datapoint_type: $datapointType
      source: $source
      type: $type
    ) {
      label: key
      value: key
    }
  }
`;
const GET_DATAPOINT_FOLLOWUP_OPTIONS = gql`
  query Query(
    $diseaseUuid: String!
    $datapointType: String
    $source: String
    $type: String
  ) {
    DataPointFollowOption: GetAllDataPoints(
      disease_uuid: $diseaseUuid
      datapoint_type: $datapointType
      source: $source
      type: $type
    ) {
      label: key
      value: key
    }
  }
`;
const DELETE_DATAPOINT = gql`
  mutation Mutation($uuid: String!) {
    DeleteDataPoint(uuid: $uuid)
  }
`;

export const useDiseaseArea = ({ diseaseUuid = "" }) => {
  const { context, setContext } = React.useContext(DiseaseAreaContext);

  const setPersona = React.useCallback((persona) => {
    setContext({ ...context, persona: persona });
  }, []);

  const getPersona = () => {
    return context?.persona;
  };
  const getDatapoint = () => {
    return context?.datapoint;
  };

  const setPersonaEdit = React.useCallback((edit) => {
    setContext({ ...context, personaEdit: edit });
  }, []);
  const getPersonaEdit = () => {
    return context?.personaEdit;
  };

  const getDatapointEdit = () => {
    return context?.datapointEdit;
  };

  const setOpenPersonaModal = React.useCallback(({ open, edit, persona }) => {
    setContext({
      ...context,
      personaModelOpen: open,
      personaEdit: edit,
      persona: persona,
    });
  }, []);

  const setOpenDatapointModal = React.useCallback(({ open, edit, data }) => {
    setContext({
      ...context,
      datapointModelOpen: open,
      datapointEdit: edit,
      datapoint: data,
    });
  }, []);

  const useAddPersona = useMutation(ADD_PERSONA, {
    update(cache, { data: { AddPersona } }) {
      const existing = cache.readQuery({
        query: GET_PERSONA,
        variables: { diseaseUuid: diseaseUuid },
      });
      const newData = [...existing.GetAllPersonas, AddPersona];
      cache.writeQuery({
        query: GET_PERSONA,
        variables: { diseaseUuid: diseaseUuid },
        data: { GetAllPersonas: newData },
      });
    },
  });
  const useUpdatePersona = useMutation(UPDATE_PERSONA, {
    update(cache, { data: { UpdatePersona } }) {
      const existing = cache.readQuery({
        query: GET_PERSONA,
        variables: { diseaseUuid: diseaseUuid },
      });
      const newData = existing.GetAllPersonas.map((item) =>
        item.uuid !== UpdatePersona.uuid ? item : UpdatePersona
      );
      cache.writeQuery({
        query: GET_PERSONA,
        variables: { diseaseUuid: diseaseUuid },
        data: { GetAllPersonas: newData },
      });
    },
  });
  const useGetPersonas = useLazyQuery(GET_PERSONA);
  const useDeletePersona = useMutation(DELETE_PERSONA, {
    update(cache, { data: { DeletePersona } }) {
      const existing = cache.readQuery({
        query: GET_PERSONA,
        variables: { diseaseUuid: diseaseUuid },
      });
      const newData = existing.GetAllPersonas.filter(
        (row) => row.uuid !== DeletePersona
      );
      cache.writeQuery({
        query: GET_PERSONA,
        variables: { diseaseUuid: diseaseUuid },
        data: { GetAllPersonas: newData },
      });
    },
  });
  const useAddDataPoint = useMutation(ADD_DATAPOINT, {
    update(cache, { data: { AddDataPoint } }) {
      const existing = cache.readQuery({
        query: GET_DATAPOINT,
        variables: { diseaseUuid: diseaseUuid },
      });
      const newData = [...existing.GetAllDataPoints, AddDataPoint];
      cache.writeQuery({
        query: GET_DATAPOINT,
        variables: { diseaseUuid: diseaseUuid },
        data: { GetAllDataPoints: newData },
      });
    },
  });
  const useUpdateDataPoint = useMutation(ADD_DATAPOINT, {
    update(cache, { data: { AddDataPoint } }) {
      const existing = cache.readQuery({
        query: GET_DATAPOINT,
        variables: { diseaseUuid: diseaseUuid },
      });
      const newData = existing.GetAllDataPoints.map((item) =>
        item.uuid !== AddDataPoint.uuid ? item : AddDataPoint
      );
      cache.writeQuery({
        query: GET_DATAPOINT,
        variables: { diseaseUuid: diseaseUuid },
        data: { GetAllDataPoints: newData },
      });
    },
  });
  const useDeleteDatapoint = useMutation(DELETE_DATAPOINT, {
    update(cache, { data: { DeleteDataPoint } }) {
      const existing = cache.readQuery({
        query: GET_DATAPOINT,
        variables: { diseaseUuid: diseaseUuid },
      });
      const newData = existing.GetAllDataPoints.filter(
        (row) => row.uuid !== DeleteDataPoint
      );
      cache.writeQuery({
        query: GET_DATAPOINT,
        variables: { diseaseUuid: diseaseUuid },
        data: { GetAllDataPoints: newData },
      });
    },
  });
  const useGetDatapoints = useLazyQuery(GET_DATAPOINT);
  const useGetDPOption = useLazyQuery(GET_DATAPOINT_OPTIONS);
  const useGetDPFollowOption = useLazyQuery(GET_DATAPOINT_FOLLOWUP_OPTIONS);

  return {
    useAddPersona,
    useGetPersonas,
    useDeletePersona,
    useUpdatePersona,
    setPersona,
    getPersona,
    setPersonaEdit,
    getPersonaEdit,
    setOpenPersonaModal,
    openPersonaModal: context?.personaModelOpen,
    useUpdateDataPoint,
    useAddDataPoint,
    useGetDatapoints,
    useDeleteDatapoint,
    setOpenDatapointModal,
    openDataModal: context?.datapointModelOpen,
    getDatapoint,
    getDatapointEdit,
    useGetDPOption,
    useGetDPFollowOption,
  };
};
