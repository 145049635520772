import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { createContext } from "react";
const NudgeContext = createContext();
export const NudgeContextProvider = ({ children }) => {
  const [context, setContextData] = React.useState({});
  const setContext = React.useCallback((data) => {
    setContextData(data);
  }, []);
  const values = React.useMemo(
    () => ({ context, setContext }),
    [context, setContext]
  );

  return (
    <NudgeContext.Provider value={values}>{children}</NudgeContext.Provider>
  );
};

const ADD_NUDGE = gql`
  mutation Mutation(
    $nudge: String!
    $disease_uuid: String
    $nudge_type: String
    $module_id: String
    $frequency_id: String
  ) {
    AddNudge(
      nudge: $nudge
      nudge_type: $nudge_type
      disease_uuid: $disease_uuid
      module_id: $module_id
      frequency_id: $frequency_id
    ) {
      nudge_id
      nudge
      disease_uuid
      nudgeType {
        name
        uuid
      }
      module {
        name
        uuid
      }
      frequency {
        name
        uuid
      }
      disease {
        name
        uuid
      }
    }
  }
`;
const UPDATE_NUDGE = gql`
  mutation Mutation(
    $nudge_id: String!
    $nudge: String!
    $disease_uuid: String
    $nudge_type: String
    $module_id: String
    $frequency_id: String
  ) {
    UpdateNudge(
      nudge_id: $nudge_id
      nudge: $nudge
      nudge_type: $nudge_type
      disease_uuid: $disease_uuid
      module_id: $module_id
      frequency_id: $frequency_id
    ) {
      nudge_id
      nudge
      disease_uuid
      nudgeType {
        name
        uuid
      }
      module {
        name
        uuid
      }
      frequency {
        name
        uuid
      }
    }
  }
`;
const DELETE_NUDGE = gql`
  mutation Mutation($nudge_id: String!) {
    DeleteNudge(nudge_id: $nudge_id)
  }
`;
const GET_NUDGE = gql`
  query Query($disease_uuid: String!) {
    GetAllNudges(disease_uuid: $disease_uuid) {
      nudge_id
      nudge
      disease_uuid
      nudgeType {
        name
        uuid
      }
      module {
        name
        uuid
      }
      frequency {
        name
        uuid
      }
      disease {
        name
        uuid
      }
    }
  }
`;

const GET_CHANNELS = gql`
  query Query {
    GetAllChannels {
      name
      uuid
    }
  }
`;
const GET_MODULES = gql`
  query Query {
    GetAllModules {
      name
      uuid
    }
  }
`;
const GET_FREQUENCIES = gql`
  query Query {
    GetAllFrequencies {
      name
      uuid
    }
  }
`;
const GET_NUDGETYPES = gql`
  query Query {
    GetAllNudgeTypes {
      name
      uuid
    }
  }
`;
export const useNudge = ({ disease_uuid = "" }) => {
  const { context, setContext } = React.useContext(NudgeContext);

  const setNudge = React.useCallback((nudge) => {
    setContext({ ...context, nudge: nudge });
  }, []);

  const getNudge = () => {
    return context?.nudge;
  };

  const setNudgeEdit = React.useCallback((edit) => {
    setContext({ ...context, nudgeEdit: edit });
  }, []);
  const getNudgeEdit = () => {
    return context?.nudgeEdit;
  };

  const setOpenNudgeModal = React.useCallback(({ open, edit, nudge }) => {
    setContext({
      ...context,
      nudgeModelOpen: open,
      nudgeEdit: edit,
      nudge: nudge,
    });
  }, []);

  const useAddNudge = useMutation(ADD_NUDGE, {
    update(cache, { data: { AddNudge } }) {
      const existing = cache.readQuery({
        query: GET_NUDGE,
        variables: { disease_uuid: disease_uuid },
      });
      const newData = [...existing.GetAllNudges, AddNudge];
      cache.writeQuery({
        query: GET_NUDGE,
        variables: { disease_uuid: disease_uuid },
        data: { GetAllNudges: newData },
      });
    },
  });
  const useUpdateNudge = useMutation(UPDATE_NUDGE, {
    update(cache, { data: { UpdateNudge } }) {
      const existing = cache.readQuery({
        query: GET_NUDGE,
        variables: { disease_uuid: disease_uuid },
      });
      const newData = existing.GetAllNudges.map((item) =>
        item.uuid !== UpdateNudge.uuid ? item : UpdateNudge
      );
      cache.writeQuery({
        query: GET_NUDGE,
        variables: { disease_uuid: disease_uuid },
        data: { GetAllNudges: newData },
      });
    },
  });
  const useGetNudge = useLazyQuery(GET_NUDGE);
  const useGetChannels = useLazyQuery(GET_CHANNELS);
  const useGetModules = useLazyQuery(GET_MODULES);
  const useGetFrequencies = useLazyQuery(GET_FREQUENCIES);
  const useGetNudgeTypes = useLazyQuery(GET_NUDGETYPES);
  const useDeleteNudge = useMutation(DELETE_NUDGE, {
    update(cache, { data: { DeleteNudge } }) {
      const existing = cache.readQuery({
        query: GET_NUDGE,
        variables: { disease_uuid: disease_uuid },
      });
      const newData = existing.GetAllNudges.filter(
        (row) => row.nudge_id !== DeleteNudge
      );
      cache.writeQuery({
        query: GET_NUDGE,
        variables: { disease_uuid: disease_uuid },
        data: { GetAllNudges: newData },
      });
    },
  });
  return {
    useAddNudge,
    useGetNudge,
    useDeleteNudge,
    useUpdateNudge,
    setNudge,
    getNudge,
    setNudgeEdit,
    getNudgeEdit,
    setOpenNudgeModal,
    openNudgeModal: context?.nudgeModelOpen,
    useGetChannels,
    useGetNudgeTypes,
    useGetModules,
    useGetFrequencies,
  };
};
