import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { createContext } from "react";
const DiseaseContext = createContext();
export const DiseaseContextProvider = ({ children }) => {
  const [context, setContextData] = React.useState({});
  const setContext = React.useCallback((data) => {
    setContextData(data);
  }, []);
  const values = React.useMemo(
    () => ({ context, setContext }),
    [context, setContext]
  );

  return (
    <DiseaseContext.Provider value={values}>
      {children}
    </DiseaseContext.Provider>
  );
};

const ADD_DISEASE = gql`
  mutation Mutation($name: String!, $description: String) {
    AddDisease(name: $name, description: $description) {
      createdOn
      description
      lastUpdatedOn
      name
      uuid
    }
  }
`;
const UPDATE_DISEASE = gql`
  mutation Mutation($uuid: String!, $name: String!, $description: String) {
    UpdateDisease(uuid: $uuid, name: $name, description: $description) {
      createdOn
      description
      lastUpdatedOn
      name
      uuid
    }
  }
`;
const DELETE_DISEASE = gql`
  mutation Mutation($uuid: String!) {
    DeleteDisease(uuid: $uuid)
  }
`;
const GET_DISEASE = gql`
  query Query{
    GetAllDiseases{
      name
      uuid
      description
      createdOn
      lastUpdatedOn
    }
  }
`;


export const useDisease = ({ }) => {
  const { context, setContext } = React.useContext(DiseaseContext);

  const setDisease = React.useCallback((disease) => {
    setContext({ ...context, disease: disease });
  }, []);

  const getDisease = () => {
    return context?.disease;
  };

  const setDiseaseEdit = React.useCallback((edit) => {
    setContext({ ...context, diseaseEdit: edit });
  }, []);
  const getDiseaseEdit = () => {
    return context?.diseaseEdit;
  };

  const setOpenDiseaseModal = React.useCallback(({ open, edit, disease }) => {
    setContext({
      ...context,
      diseaseModelOpen: open,
      diseaseEdit: edit,
      disease: disease,
    });
  }, []);



  const useAddDisease = useMutation(ADD_DISEASE, {
    update(cache, { data: { AddDisease } }) {
      const existing = cache.readQuery({
        query: GET_DISEASE,
        variables: {},
      });
      const newData = [...existing.GetAllDiseases, AddDisease];
      cache.writeQuery({
        query: GET_DISEASE,
        variables: { },
        data: { GetAllDiseases: newData },
      });
    },
  });
  const useUpdateDisease = useMutation(UPDATE_DISEASE, {
    update(cache, { data: { UpdateDisease } }) {
      const existing = cache.readQuery({
        query: GET_DISEASE,
        variables: {},
      });
      const newData = existing.GetAllDiseases.map((item) =>
        item.uuid !== UpdateDisease.uuid ? item : UpdateDisease
      );
      cache.writeQuery({
        query: GET_DISEASE,
        variables: {  },
        data: { GetAllDiseases: newData },
      });
    },
  });
  const useGetDisease = useLazyQuery(GET_DISEASE);
  const useDeleteDisease = useMutation(DELETE_DISEASE, {
    update(cache, { data: { DeleteDisease } }) {
      const existing = cache.readQuery({
        query: GET_DISEASE,
        variables: { },
      });
      const newData = existing.GetAllDiseases.filter(
        (row) => row.uuid !== DeleteDisease
      );
      cache.writeQuery({
        query: GET_DISEASE,
        variables: { },
        data: { GetAllDiseases: newData },
      });
    },
  });
  return {
    useAddDisease,
    useGetDisease,
    useDeleteDisease,
    useUpdateDisease,
    setDisease,
    getDisease,
    setDiseaseEdit,
    getDiseaseEdit,
    setOpenDiseaseModal,
    openDiseaseModal: context?.diseaseModelOpen,
  };
};
