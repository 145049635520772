import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Text } from "../../components";
import { URLS, colors } from "../../constant";
import { useDataDictionary } from "../../hooks";
export const DictionaryLeft = () => {
  const { uuid, diseaseId } = useParams();
  const { useGetDatapoints } = useDataDictionary();

  const [GetDatapoint, { data, loading, error }] = useGetDatapoints;
  useEffect(() => {
    GetDatapoint({ variables: { diseaseUuid: diseaseId } });
  }, [GetDatapoint, diseaseId]);
  return (
    <>
      <Table style={{ backgroundColor: colors.WHITE }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Key</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Type</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Source</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Datapoint Type</Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            data &&
            data?.GetAllDataPoints.map((row, idx) => (
              <TableRows
                key={idx}
                row={row}
                uuid={uuid}
                diseaseId={diseaseId}
              />
            ))}
        </TableBody>
      </Table>
    </>
  );
};

const TableRows = ({ row, uuid, diseaseId }) => {
  const navigate = useNavigate();
  return (
    <>
      <TableRow
        onClick={() => {
          navigate(
            "/app/" + URLS.DATA_DICTIONARY + "/" + row.uuid + "/" + diseaseId
          );
        }}
        style={{
          backgroundColor: uuid === row.uuid ? colors.LIGHT_BLUE : colors.WHITE,
          cursor: "pointer",
        }}
        key={row.uuid}
      >
        <TableCell component="th" scope="row">
          <Text> {row.key}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.type}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.source}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.datapoint_type}</Text>
        </TableCell>
      </TableRow>
    </>
  );
};
