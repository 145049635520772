import { Stack } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Btn, TextBox } from "../../../components";
import { useDiseaseArea } from "../../../hooks";
import { useParams } from "react-router-dom";
export const PersonaForm = ({ SetOpen }) => {
  const { diseaseId } = useParams();
  const { useAddPersona, useUpdatePersona, getPersonaEdit, getPersona } =
    useDiseaseArea({
      diseaseUuid: diseaseId,
    });

  const [AddPersona] = useAddPersona;
  const [UpdatePersona] = useUpdatePersona;
  const edit = getPersonaEdit();
  const persona = getPersona();

  return (
    <>
      <Formik
        initialValues={{
          persona: edit ? persona?.title : "",
          rangefrom: edit ? persona?.score?.[0] : "",
          rangeto: edit ? persona?.score?.[1] : "",
        }}
        validationSchema={Yup.object().shape({
          persona: Yup.string().max(255).required("Persona is required"),
          rangefrom: Yup.number().min(0).required("Range from is required"),
          rangeto: Yup.number().min(0).required("Range to is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          let score = [Number(values?.rangefrom), Number(values?.rangeto)];

          if (edit) {
            UpdatePersona({
              variables: {
                uuid: persona?.uuid,
                title: values?.persona,
                score: score,
                diseaseUuid: diseaseId,
              },
              onCompleted: () => {
                setSubmitting(false);
                SetOpen(false);
              },
              onError: () => {
                setSubmitting(false);
              },
            });
          } else {
            AddPersona({
              variables: {
                title: values?.persona,
                score: score,
                diseaseUuid: diseaseId,
              },
              onCompleted: () => {
                setSubmitting(false);
                SetOpen(false);
              },
              onError: () => {
                setSubmitting(false);
              },
            });
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextBox
                label={"Persona"}
                name={"persona"}
                placeholder="Persona"
                error={Boolean(touched.persona && errors.persona)}
                helperText={touched.persona && errors.persona}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.persona}
              />
              <Stack direction={"row"} spacing={1}>
                <TextBox
                  label={"Range from"}
                  name={"rangefrom"}
                  placeholder="From"
                  error={Boolean(touched.rangefrom && errors.rangefrom)}
                  helperText={touched.rangefrom && errors.rangefrom}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.rangefrom}
                />
                <TextBox
                  label={"Range to"}
                  name={"rangeto"}
                  placeholder="To"
                  error={Boolean(touched.rangeto && errors.rangeto)}
                  helperText={touched.rangeto && errors.rangeto}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.rangeto}
                />
              </Stack>

              <Stack direction={"row"} spacing={1} justifyContent="flex-end">
                <Btn
                  onClick={() => {
                    SetOpen(false);
                  }}
                  variant="outlined"
                  disabled={isSubmitting}
                >
                  Close
                </Btn>
                <Btn variant="contained" type="submit" disabled={isSubmitting}>
                  Save
                </Btn>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
