import React, { useEffect, useState } from "react";
import { Btn } from "../../components/global/Btn";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Text } from "../../components/global/Text";
import { csvFileToArray } from "../../helpers/customFunction";
import { useDiseaseList, useImportArea } from "../../hooks";
import { PreviewContainer } from "./PreviewContainer";
import { SecondaryNavBar } from "../../components";
import { useNavigate, useParams } from "react-router-dom";

export const ImportArea = () => {
  const { diseaseId } = useParams();
  const navigate = useNavigate();

  const { useGetDisease } = useDiseaseList({});
  let [
    GetDisease,
    {
      data: { GetDisease: diseaseDetails = [] } = {},
      disease_loading,
      disease_error,
    },
  ] = useGetDisease;
  console.log("xxx diseaseDetails:--->", diseaseDetails);
  const diseaseLabel = diseaseDetails?.name ? diseaseDetails?.name : "NA";
  useEffect(() => {
    GetDisease({ variables: { uuid: diseaseId } });
  }, [GetDisease]);

  const style = {
    display: "flex",
    justifyContent: "center",
    height: "80vh",
    alignitems: "center",
  };
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const fileInput = React.useRef();
  const fileReader = new FileReader();

  const {
    getExcelData,
    setImportModalOpen,
    setExcelData,
    setExcelDataPoint,
    openImportModal,
  } = useImportArea();

  const handleOnChange = (e) => {
    if (e.target?.files?.[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };
  const handleCloseModal = (open) => {
    // setPreviewModal((prevState) => ({
    //   ...prevState,
    //   open: open,
    // }));
    setImportModalOpen(open);
    setFile(null);
    setFileName("");
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput).then((res) => {
          setExcelData([...res]);
          let keys = Object.keys(res[0]);
          setExcelDataPoint([...keys]);
          handleCloseModal(true);
        });
      };

      fileReader.readAsText(file);
    }
  };
  let excelDataExist = getExcelData().length;
  return (
    <>
      <SecondaryNavBar
        breadcrum={[{ label: diseaseLabel }, { label: "Import" }]}
      />
      {!openImportModal && (
        <div style={style}>
          <Stack
            style={{
              width: "500px",
            }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <form>
              <Text style={{ textAlign: "center" }} size={30}>
                Bulk import from CSV
              </Text>
              <input
                style={{ display: "none" }}
                ref={fileInput}
                type={"file"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleOnChange}
                onClick={(e) => (e.target.value = null)}
              />
              <Stack
                style={{
                  width: "auto",
                }}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Btn
                  variant="contained"
                  onClick={(e) => {
                    fileInput.current.click();
                  }}
                  disabled={file ? true : false}
                >
                  Choose File
                </Btn>
                {fileName && (
                  <Text
                    style={{ textAlign: "center", color: "#4caf50" }}
                    size={20}
                  >
                    {fileName}
                  </Text>
                )}
              </Stack>
              {file && fileName && (
                <Stack justifyContent="center" alignItems="center">
                  <Btn
                    variant="contained"
                    style={{
                      marginTop: "2%",
                    }}
                    onClick={handleOnSubmit}
                    alignItems="center"
                  >
                    Load CSV
                  </Btn>
                </Stack>
              )}
            </form>
          </Stack>
        </div>
      )}
      {openImportModal && excelDataExist && <PreviewContainer />}
    </>
  );
};
