import { colors } from "../constant";
import csvtojson from "csvtojson";
export const customTableRowColor = (idx) => {
  return idx % 2 === 0 ? colors.LIGHT_BLUE : colors.WHITE;
};

export const formatFetchDataToLabels = (data) => {
  let result = data.map(({ name, uuid }) => {
    return {
      label: name,
      value: uuid,
    };
  });
  return result;
};
export const csvFileToArray = async (csvStr) => {
  const jsonArray = await csvtojson().fromString(csvStr);
  return jsonArray;
};

// https://dev2-fedsvc.solutions.iqvia.com/authenticationendpoint/login.do?client_id=dr2S89Qg27X_hTizBB8jq1HItbka&commonAuthCallerPath=%2Foauth2%2Fauthorize&forceAuth=false&passiveAuth=false
// &redirect_uri=https%3A%2F%2Fdev2-myapps.solutions.iqvia.com%2Foauth2client
// &response_type=code&scope=openid+email+profile
// &tenantDomain=carbon.super
// &sessionDataKey=c21c14de-421c-433c-bc52-62c2c4bfa9c2
// &relyingParty=dr2S89Qg27X_hTizBB8jq1HItbka
// &type=oidc
// &sp=OIDC-CIO-SDA-LANDPAGE
// &isSaaSApp=false
// &authenticators=IdentifierExecutor%3ALOCAL
export const getAuthUrl = () => {
  const rootUrl =
    "https://dev2-fedsvc.solutions.iqvia.com/authenticationendpoint/login.do";

  const options = {
    client_id: "3nRxL6yszejfcryJ5HS8z8IAjTca",
    commonAuthCallerPath: "/oauth2/authorize",
    forceAuth: false,
    passiveAuth: false,
    redirect_uri: "http://localhost:3000/signin-callack/oauth2client",
    scope: "openid",
    type: "oidc",
    sp: "SVP-APP-DEV-ENV",
    isSaaSApp: false,
    authenticators: "IdentifierExecutor:LOCAL",
    response_type: "code",
  };
  const qs = new URLSearchParams(options);
  return `${rootUrl}?${qs.toString()}`;
};
