export const colors = {
  PRIMARY: "#004a87",
  ACCENT: "#830065",
  MAIN_BG: "#f4f4f4",
  WHITE: "#ffffff",
  LIGHT_BLUE: "#f1f6f9",
  LIGHT_GREY: "#959ca0",
  ALMOST_BLACK: "#2b3a42",
  GREY_BG: "#f4f4f4",
  TEXT_GREY: "#606b71",
};
