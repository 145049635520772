import { Grid, IconButton } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Btn } from "../global/Btn";
import { TextBox } from "../global/TextBox";
import { debounce, isEmpty, values } from "lodash";
import { Close } from "@mui/icons-material";
import { Text } from "../global/Text";
import { SelectBox } from "../global/SelectBox";
export const RadioItem = React.memo(
  ({
    onItemChange,
    name = "",
    value = "",
    score = 0,
    action = "",
    onDelete,
    datapointOption = [],
  }) => {
    const [input, setInput] = useState({
      name: name,
      value: value,
      score: score,
      action: action,
    });
    const handleChange = (e) => {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    };

    useEffect(() => {
      if (!isEmpty(input)) {
        onItemChange(input);
      }
    }, [input]);
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={3}>
          <TextBox
            name={"name"}
            onChange={handleChange}
            value={input?.name}
            label={"Name"}
          />
        </Grid>
        <Grid item xs={3}>
          <TextBox
            name={"value"}
            onChange={handleChange}
            value={input?.value}
            label={"Value"}
          />
        </Grid>
        <Grid item xs={2}>
          <TextBox
            type={"number"}
            name={"score"}
            onChange={handleChange}
            value={input?.score}
            label={"Score"}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectBox
            name={"action"}
            onChange={handleChange}
            value={input?.action}
            label={"Action"}
            options={datapointOption}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={onDelete}
            style={{ marginTop: "20px" }}
            size="small"
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
);

export const RadioOptions = ({
  name,
  value = [],
  label = "",
  datapointOption,
  onChangeOptionsValue = () => {},
}) => {
  let arr = [];
  const obj = value.reduce((obj, row, index) => {
    obj[index.toString()] = row;
    arr.push(index.toString());
    return obj;
  }, {});

  const [optionCount, setOptionCount] = useState(arr);
  const [itemsObj, setItemsObj] = useState(obj);

  // useEffect(() => {
  //   if (isEmpty(itemsObj)) return;
  //   console.log("🚀 ~ file: RadioOptions.js:100 ~ value:", value);
  //   const obj = value.reduce((obj, row, index) => {
  //     obj[index.toString()] = row;
  //     return obj;
  //   }, {});
  //   setItemsObj(obj);
  // }, [value]);
  useEffect(() => {
    if (isEmpty(itemsObj)) return;

    onChangeOptionsValue({
      type: "select",
      target: { value: values(itemsObj), id: name, name: name },
    });
  }, [itemsObj, onChangeOptionsValue]);

  const onChangeComplete = useCallback((val, index) => {
    setItemsObj((prevState) => ({
      ...prevState,
      [index]: val,
    }));
  }, []);
  const onDelete = useCallback(
    (index) => {
      let obj = itemsObj;

      delete obj[index];

      setItemsObj({ ...obj });
      let arr = [...optionCount];
      const newarr = arr.filter((row) => row !== index);
      setOptionCount(newarr);
    },

    [itemsObj]
  );
  const onChangHandler = useRef(debounce(onChangeComplete, 500), []).current;
  return (
    <>
      <Text size={14}>{label}</Text>
      {itemsObj &&
        optionCount.map((row, idx) => (
          <>
            <RadioItem
              key={row}
              onItemChange={(obj) => {
                onChangHandler(obj, row);
              }}
              onDelete={() => {
                onDelete(row);
              }}
              datapointOption={datapointOption}
              name={itemsObj[row]?.name}
              value={itemsObj[row]?.value}
              score={itemsObj[row]?.score}
              action={itemsObj[row]?.action}
            />
          </>
        ))}

      <Btn
        onClick={() => {
          setOptionCount((prevState) => [
            ...prevState,
            (prevState.length + 1).toString(),
          ]);
        }}
      >
        Add More
      </Btn>
    </>
  );
};
