import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StorageHelper } from "./storage";

export const AuthWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    const auth = StorageHelper.Get("login");
    if (!auth || auth !== "true") {
      StorageHelper.Set("redirect", pathname);
      // if (pathname !== "/action/item/" + item_id) {
      navigate("/login", { replace: true });
      // }
    }
  }, []);
  return <>{children}</>;
};
