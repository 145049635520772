import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GridLayout, SecondaryNavBar } from "../../components";
import { useDiseaseList } from "../../hooks";
import { DictionaryLeft } from "./DictionaryLeft";
import { DictionaryRight } from "./DictionaryRight";

export const DictionaryWrapper = () => {
  const { diseaseId } = useParams();
  const navigate = useNavigate();

  const { useGetDisease } = useDiseaseList({});
  let [
    GetDisease,
    {
      data: { GetDisease: diseaseDetails = [] } = {},
      disease_loading,
      disease_error,
    },
  ] = useGetDisease;
  const diseaseLabel = diseaseDetails?.name ? diseaseDetails?.name : "NA";
  useEffect(() => {
    GetDisease({ variables: { uuid: diseaseId } });
  }, [GetDisease]);

  return (
    <>
      <SecondaryNavBar
        breadcrum={[
          { label: diseaseLabel },
          { label: "Data Dictionary" },
        ]}
      />
      <GridLayout
        leftContent={<DictionaryLeft />}
        rightContent={<DictionaryRight />}
      />
    </>
  );
};
