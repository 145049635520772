import { useEffect } from "react";
import { getAuthUrl } from "./customFunction";
import { useNavigate } from "react-router-dom";
import { replace } from "formik";

export const AuthCallback = () => {
  return <></>;
};

export const AuthRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const url = getAuthUrl();
    console.log("🚀 ~ file: AuthCallback.js:11 ~ useEffect ~ url:", url);
    window.open(url, "_self");
  }, []);

  return <></>;
};
