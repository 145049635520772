import React, { useEffect } from "react";
// import CloseIcon from "@material-ui/icons/Close";

// import { Snackbar, IconButton } from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";

import { alertService } from "../../helpers/alertService";
import { ALERT_ERROR, ALERT_PROGRESS, ALERT_SUCCESS } from "../../helpers";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { Close } from "@mui/icons-material";
export function CustomSnackBar() {
  const [open, setOpen] = React.useState(false);
  const [messages, setMessage] = React.useState("");
  const [colorType, setSnackbarType] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    alertService.getMessage().subscribe((response) => {
      if (response.status === ALERT_ERROR) {
        setMessage(response.message);
        setSnackbarType("error");
        setOpen(true);
      }
      if (response.status === ALERT_SUCCESS) {
        setMessage(response.message);
        setSnackbarType("success");
        setOpen(true);
      }
      if (response.status === ALERT_PROGRESS) {
        setMessage(response.message);
        setSnackbarType("info");
        setOpen(true);
      }
    });
  }, []);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={4000}
      open={open}
      onClose={handleClose}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    >
      <Alert onClose={handleClose} severity={colorType}>
        {messages}
      </Alert>
    </Snackbar>
  );
}
