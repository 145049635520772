import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

export const BreadCrumb = ({ data = [], handleClick = () => {} }) => {
  return (
    <>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          {data.map(({ to, label, color = "inherit" }, index) => {
            if (index === data.length - 1) {
              return (
                <Typography key={index} color="text.primary">
                  {label}
                </Typography>
              );
            }
            return (
              <Link underline="hover" color={color} key={index} href={to}>
                {label}
              </Link>
            );
          })}
        </Breadcrumbs>
      </div>
    </>
  );
};
