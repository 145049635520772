import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ModalContainer, PageHeader, TitleText } from "../../components";
import { NudgeForm } from "./NudgeForm";
import { NudgeListView } from "./NudgeListView";
import { NudgeContextProvider, useNudge } from "../../hooks";
import { formatFetchDataToLabels } from "../../helpers";

export const NudgeContainer = () => {
  return (
    <>
      <NudgeContextProvider>
        <Stack spacing={3}>
          <NudgeContainerArea />
        </Stack>
      </NudgeContextProvider>
    </>
  );
};

const NudgeContainerArea = () => {
  const {
    openNudgeModal,
    setOpenNudgeModal,
    useGetChannels,
    useGetNudgeTypes,
    useGetModules,
    useGetFrequencies,
  } = useNudge({
    diseaseUuid: "",
  });

  const [GetChannels, { data: { GetAllChannels = [] } = {} }] = useGetChannels;
  const [GetNudgeTypes, { data: { GetAllNudgeTypes = [] } = {} }] =
    useGetNudgeTypes;
  const [GetModules, { data: { GetAllModules = [] } = {} }] = useGetModules;
  const [GetFrequencies, { data: { GetAllFrequencies = [] } = {} }] =
    useGetFrequencies;

  const channels = formatFetchDataToLabels(GetAllChannels);
  const modules = formatFetchDataToLabels(GetAllModules);
  const frequencies = formatFetchDataToLabels(GetAllFrequencies);
  const nudgeTypes = formatFetchDataToLabels(GetAllNudgeTypes);
  console.log("xxx modules:--->", modules);

  useEffect(() => {
    GetChannels({ variables: {} });
    GetNudgeTypes({ variables: {} });
    GetModules({ variables: {} });
    GetFrequencies({ variables: {} });
  }, [GetChannels, GetNudgeTypes, GetModules, GetFrequencies]);

  const handleClose = (open) => {
    setOpenNudgeModal({
      open: open,
      edit: false,
      nudge: {},
    });
  };
  return (
    <>
      {" "}
      <Stack>
        <PageHeader
          left={
            <TitleText
              title={"Nudges"}
              subtitle={
                "Nudges based on clinical & behavioral patterns of patients"
              }
            />
          }
          right={
            <IconButton
              onClick={() => {
                setOpenNudgeModal({
                  open: true,
                  edit: false,
                  nudge: {},
                });
              }}
              color="primary"
            >
              <AddOutlinedIcon />
            </IconButton>
          }
        />
        {openNudgeModal && (
          <ModalContainer title="New Nudge" onClose={handleClose}>
            <NudgeForm
              SetOpen={handleClose}
              channels={channels}
              modules={modules}
              nudgeTypes={nudgeTypes}
              frequencies={frequencies}
            />
          </ModalContainer>
        )}
        <NudgeListView />
      </Stack>
    </>
  );
};
