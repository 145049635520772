import { Grid } from "@mui/material";

export const GridLayout = ({ leftContent, rightContent }) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        {leftContent}
      </Grid>
      <Grid style={{ overflow: "auto" }} item xs={9}>
        {rightContent}
      </Grid>
    </Grid>
  );
};
