import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { ConfirmDialog, SelectButton, Text } from "../../components";
import { colors } from "../../constant";
import { customTableRowColor } from "../../helpers";
import { useNudge } from "../../hooks";
import { useParams } from "react-router-dom";
export const NudgeListView = () => {
  const { diseaseId } = useParams();
  const { useGetNudge, useDeleteNudge } = useNudge({ disease_uuid: diseaseId });

  const [GetNudge, { data, loading, error }] = useGetNudge;

  useEffect(() => {
    GetNudge({ variables: { disease_uuid: diseaseId } });
  }, [GetNudge]);
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Nudge</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Nudge Type</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Module</Text>
            </TableCell>

            <TableCell>
              <Text color={colors.TEXT_GREY}> Frequency</Text>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            data &&
            data?.GetAllNudges.map((row, idx) => (
              <TableRows key={idx} diseaseId={diseaseId} row={row} idx={idx} />
            ))}
        </TableBody>
      </Table>
    </>
  );
};

const TableRows = ({ row, idx, diseaseId }) => {
  console.log("xxx rows :->", row);
  const { useDeleteNudge, setNudge, setNudgeEdit, setOpenNudgeModal } =
    useNudge({
      disease_uuid: diseaseId,
    });
  const [DeleteNudge] = useDeleteNudge;
  const [open, setOpen] = React.useState(false);
  const handleDelete = () => {
    DeleteNudge({ variables: { nudge_id: row.nudge_id } });
  };
  return (
    <>
      {open && (
        <ConfirmDialog
          title={"Confirm Delete"}
          discription={"Are you sure once delete can't be undone?"}
          onClose={() => {
            setOpen(false);
          }}
          positive={() => {
            handleDelete();
          }}
          positiveLabel="Yes"
          negativeLabel="No"
        />
      )}
      <TableRow key={row.nudge_id}>
        <TableCell component="th" scope="row">
          <Text> {row.nudge}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.nudgeType.name}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.module.name}</Text>
        </TableCell>

        <TableCell>
          <Text>{row.frequency.name}</Text>
        </TableCell>
        <TableCell>
          <SelectButton
            type="icon"
            onSelect={(item) => {
              switch (item.value) {
                case "delete":
                  {
                    setOpen(true);
                  }
                  break;
                case "edit":
                  {
                    setOpenNudgeModal({
                      open: true,
                      edit: true,
                      nudge: row,
                    });
                  }
                  break;
              }
            }}
            options={[
              { label: "Edit", value: "edit" },
              { label: "Delete", value: "delete" },
            ]}
          >
            <MoreVertOutlinedIcon fontSize="8" />
          </SelectButton>
        </TableCell>
      </TableRow>
    </>
  );
};
