import { Grid, IconButton, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { Btn, Padding, PageHeader, Text, TextBox } from "../../components";
import { useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { useDataDictionary, useSnackbar } from "../../hooks";
import { ProcessGrapqlError } from "../../App";

export const DictionaryRight = () => {
  const { uuid, diseaseId } = useParams();
  const [optionCount, setOptionCount] = useState([]);
  const [values, setInputs] = useState([]);
  const { openSnackBar } = useSnackbar();
  const { useAddDataDictionaries, useGetDataDictionaries } =
    useDataDictionary();
  const [AddDataDictionaries] = useAddDataDictionaries;
  const [GetDataDictionaries] = useGetDataDictionaries;
  const handleChange = (row, idx) => {
    let arr = [...values];
    arr[idx] = row;
    setInputs(arr);
  };
  const handleDelete = (idx) => {
    const arr = [...values];
    const counts = [...optionCount];
    const newCounts = counts.filter((row) => row !== idx.toString());
    const newArr = arr.filter((row, i) => idx !== i);
    setOptionCount(newCounts);
    setInputs(newArr);
  };

  useEffect(() => {
    GetDataDictionaries({
      variables: { reference: uuid },
      onCompleted: ({ GetDataDictionaries }) => {
        setInputs(GetDataDictionaries);
        const arr = GetDataDictionaries.reduce((arr, row, idx) => {
          arr.push(idx.toString());
          return arr;
        }, []);
        setOptionCount(arr);
      },
    });
  }, [GetDataDictionaries, uuid]);
  const handleSave = () => {
    AddDataDictionaries({
      variables: { inputs: values },
      onCompleted: () => {
        openSnackBar({
          message: "Successfully updated",
          type: "success",
        });
      },
      onError: (err) => {
        ProcessGrapqlError({ ...err, snackbar: openSnackBar });
      },
    });
  };

  return (
    <Paper elevation={0}>
      <Padding size={16}>
        <PageHeader
          left={<Text>Dictionary</Text>}
          right={
            <Btn
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </Btn>
          }
        />
        <Grid container>
          {optionCount.map((row, idx) => {
            const arr = (values?.[idx]?.values ?? []).reduce(
              (arr, row, idx) => {
                arr.push(idx.toString());
                return arr;
              },
              []
            );
            return (
              <>
                <MainLayer
                  onDataChange={(val) => {
                    handleChange(val, idx);
                  }}
                  handlekeyDelete={() => {
                    handleDelete(idx);
                  }}
                  value={values?.[idx]}
                  key={row}
                  counts={arr}
                />
              </>
            );
          })}

          <Grid item xs={12}>
            <Btn
              onClick={() => {
                setOptionCount((prevState) => [
                  ...prevState,
                  (prevState.length + 1).toString(),
                ]);
              }}
            >
              Add Key
            </Btn>
          </Grid>
        </Grid>
      </Padding>
    </Paper>
  );
};

const MainLayer = ({
  onDataChange,
  value = { key: "", values: [] },
  handlekeyDelete,
  counts = [],
}) => {
  const { uuid } = useParams();
  const [optionCount, setOptionCount] = useState(counts);
  const [input, setInput] = useState(value?.values);
  const [keyInput, setKeyInput] = useState(value?.key);
  const handleChange = (e, idx) => {
    const arr = [...input];
    arr[idx] = e.target.value ?? "";
    setInput(arr);
  };

  useEffect(() => {
    onDataChange({ key: keyInput, values: input, reference: uuid });
  }, [keyInput, input]);
  const handleDelete = (idx) => {
    const arr = [...input];
    const counts = [...optionCount];
    const newCounts = counts.filter((row) => row !== idx.toString());
    const newArr = arr.filter((row, i) => idx !== i);
    setOptionCount(newCounts);
    setInput(newArr);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={11}>
          <TextBox
            onChange={(e) => {
              setKeyInput(e.target.value);
            }}
            value={keyInput}
            name="key"
            label={"Key"}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={handlekeyDelete}
            style={{ marginTop: "20px" }}
            size="small"
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Grid style={{ marginLeft: "100px" }} item xs={12}>
        {optionCount.map((row, idx) => (
          <>
            <InnerLayer
              onChange={(e) => {
                handleChange(e, idx);
              }}
              value={input?.[idx]}
              onDelete={() => handleDelete(idx)}
              key={row}
            />
          </>
        ))}
      </Grid>
      <Grid style={{ textAlign: "end" }} item xs={12}>
        <Btn
          onClick={() => {
            setOptionCount((prevState) => [
              ...prevState,
              (prevState.length + 1).toString(),
            ]);
          }}
        >
          Add Value
        </Btn>
      </Grid>
    </>
  );
};

const InnerLayer = ({ onChange, value, onDelete }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={10}>
          <TextBox
            onChange={onChange}
            name="value"
            value={value}
            label={"Value"}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={onDelete}
            style={{ marginTop: "20px" }}
            size="small"
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};
