import { useRef, useState, useEffect } from "react";
import {
  Btn,
  DefaultContainer,
  GridLayout,
  SecondaryNavBar,
} from "../../components";
import { DiseaseLeftArea } from "./DiseaseLeftArea";
import { DiseaseContainer } from "./diseaseMaster/DiseaseContainer";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { URLS } from "../../constant";
import { DiseaseAreaContextProvider } from "../../hooks";
import { NudgeMappingContainer } from "../NudgeMapping/NudgeMappingContainer";
import { useNudgeMapping, useDiseaseList } from "../../hooks";

export const DiseaseAreaView = () => {
  const { diseaseId } = useParams();
  const navigate = useNavigate();

  const { useGetDisease } = useDiseaseList({});
  let [
    GetDisease,
    {
      data: { GetDisease: diseaseDetails = [] } = {},
      disease_loading,
      disease_error,
    },
  ] = useGetDisease;
  console.log("xxx diseaseDetails:--->", diseaseDetails);
  const diseaseLabel = diseaseDetails?.name ? diseaseDetails?.name : "NA";
  useEffect(() => {
    GetDisease({ variables: { uuid: diseaseId } });
  }, [GetDisease]);

  return (
    <>
      <DiseaseAreaContextProvider>
        <SecondaryNavBar
          breadcrum={[{ label: diseaseLabel }]}
          right={
            <Btn
              onClick={() => {
                navigate("/app/" + URLS.MANAGE_QUESTION + "/" + diseaseId);
              }}
              startIcon={<SettingsOutlinedIcon />}
            >
              Manage Questionare
            </Btn>
          }
        />
        <GridLayout
          leftContent={<DiseaseLeftArea />}
          rightContent={
            <>
              <NudgeMappingContainer componentId={1} />
            </>
          }
        />
      </DiseaseAreaContextProvider>
    </>
  );
};
