import { Close } from "@mui/icons-material";
import { Grid, IconButton, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Btn, Padding, SelectBox, TextBox } from "../../components";
import { useNudgeMapping, useSnackbar } from "../../hooks";
import { ProcessGrapqlError } from "../../App";

export const NudgeMappingForm = ({
  SetOpen,
  onClickCreateNP,
  currentNudgeMappingData: nudgemapping,
  isEditNudgeMapping: edit,
  setEditNudgeMapping,
}) => {
  const { diseaseId: disease_uuid } = useParams();
  let arr = [];
  nudgemapping?.nudge_plan?.map((row, idx) => {
    arr.push(idx.toString());
  });
  const [optionCount, setOptionCount] = useState(arr);
  const {
    useGetNudgePersona,
    useGetDataPoints,
    useAddNudgeMapping,
    useUpdateNudgeMapping,
    useGetDataPointNudge,
  } = useNudgeMapping({
    disease_uuid: disease_uuid,
  });

  const [GetNudgePersona, { data, loading, error }] = useGetNudgePersona;
  const [
    GetDatapoints,
    { data: datapoints, loading: dataloading, error: dataerror },
  ] = useGetDataPoints;

  const [
    GetDatapointNudge,
    { data: datapointNudge, loading: dataNudgeloading },
  ] = useGetDataPointNudge;
  const removeScoreOption = useCallback((index) => {
    setOptionCount((prevState) => {
      let arr = [...prevState];
      const newarr = arr.filter((row, idx) => idx != index);
      return newarr;
    });
  }, []);

  const { openSnackBar } = useSnackbar();
  const [AddNudgeMappingPlan] = useAddNudgeMapping;
  const [UpdateNudgeMappingPlan] = useUpdateNudgeMapping;
  useEffect(() => {
    GetNudgePersona({ variables: { diseaseUuid: disease_uuid } });
    GetDatapoints({ variables: { disease_uuid } });
    GetDatapointNudge({
      variables: { diseaseUuid: disease_uuid, datapointType: "Nudge" },
    });
  }, []);

  if (loading || dataloading || dataNudgeloading) {
    return <></>;
  }
  return (
    <>
      <Padding size={20}>
        <Formik
          initialValues={{
            key: edit ? nudgemapping?.key : "",
            persona: edit ? nudgemapping?.persona : "",
            label: edit ? nudgemapping?.label : "",
            nudge_plan: edit ? nudgemapping?.nudge_plan : [],
          }}
          validationSchema={Yup.object().shape({
            label: Yup.string().max(255).required("Label is required"),
            key: Yup.string().when("persona", (persona, schema) => {
              const val = persona?.[0] ?? "";

              if (val === "") {
                return schema.required(
                  "Either Datapoint or persona is required"
                );
              } else {
                return schema.nonNullable();
              }
            }),
            persona: Yup.string(),
          })}
          onSubmit={(values = {}, { setSubmitting }) => {
            if (edit) {
              UpdateNudgeMappingPlan({
                variables: {
                  uuid: nudgemapping?.uuid,
                  key: values?.key,
                  label: values?.label,
                  persona: values?.persona,
                  nudgePlan: values?.nudge_plan.map(
                    ({ action, value, nudges, type }) => {
                      return {
                        action,
                        value,
                        nudges,
                        type,
                      };
                    }
                  ),
                  diseaseId: disease_uuid,
                },
                onCompleted: () => {
                  setSubmitting(false);

                  setEditNudgeMapping(false);
                  openSnackBar({
                    message: "Successfully updated",
                    type: "success",
                  });
                },
                onError: (err) => {
                  setSubmitting(false);
                  ProcessGrapqlError({ ...err, snackbar: openSnackBar });
                },
              });

              setEditNudgeMapping(false);
            } else {
              AddNudgeMappingPlan({
                variables: {
                  key: values?.key,
                  label: values?.label,
                  persona: values?.persona,
                  nudgePlan: values?.nudge_plan,
                  diseaseId: disease_uuid,
                },
                onCompleted: () => {
                  onClickCreateNP(false);
                  openSnackBar({
                    message: "Successfully added",
                    type: "success",
                  });
                },
                onError: (err) => {
                  setSubmitting(false);
                  ProcessGrapqlError({ ...err, snackbar: openSnackBar });
                },
              });

              // onClickCreateNP(false);
            }
          }}
        >
          {({
            isSubmitting,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldTouched,
            setFieldError,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <SelectBox
                    label={"Datapoint Key"}
                    name={"key"}
                    blank={true}
                    placeholder="Datapoint Key"
                    error={Boolean(touched.key && errors.key)}
                    helperText={touched.key && errors.key}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue={[]}
                    value={values.key}
                    options={
                      (!dataloading &&
                        datapoints &&
                        datapoints.GetAllDataPoints.map((x) => {
                          let obj = {};
                          obj.label = x.key;
                          obj.value = x.key;
                          return obj;
                        })) ||
                      []
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectBox
                    label={"Persona"}
                    name={"persona"}
                    blank={true}
                    placeholder="Persona"
                    error={Boolean(touched.persona && errors.persona)}
                    helperText={touched.persona && errors.persona}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    defaultValue=""
                    value={values.persona}
                    options={
                      (!loading &&
                        data &&
                        data.GetAllPersonas.filter((x) => x.uuid).map((x) => {
                          let obj = {};
                          obj.label = x.title;
                          obj.value = x.uuid;
                          return obj;
                        })) ||
                      []
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    label={"Label"}
                    name={"label"}
                    placeholder="Enter nudgemapping label"
                    error={Boolean(touched.label && errors.label)}
                    helperText={touched.label && errors.label}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.label}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!loading && data && (
                    <SelectNudgeArea
                      iteration={optionCount}
                      nudges={data.GetAllNudges}
                      DataPointFollowOption={
                        datapointNudge?.DataPointFollowOption
                      }
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      onRowDelete={removeScoreOption}
                      input={values}
                      deleteNudgeMappForm={(index) => {
                        let newmap = values.nudge_mapp?.filter(
                          (x, idx) => idx != index
                        );
                        values.nudge_mapp = newmap;
                      }}
                      touched={touched}
                      errors={errors}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Btn
                    onClick={() => {
                      setOptionCount((prevState) => [
                        ...prevState,
                        (prevState.length + 1).toString(),
                      ]);
                    }}
                  >
                    Add Nudge Plan
                  </Btn>
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Btn
                      onClick={() => {
                        onClickCreateNP(false);
                        setEditNudgeMapping(false);
                      }}
                      variant="outlined"
                      disabled={isSubmitting}
                    >
                      Close
                    </Btn>
                    <Btn variant="contained" type="submit">
                      Save
                    </Btn>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Padding>
    </>
  );
};

const SelectNudgeArea = React.memo(
  ({
    handleBlur,
    handleChange,
    onRowDelete,
    input,
    nudges = [],
    DataPointFollowOption = [],
    deleteNudgeMappForm,
    iteration,
  }) => {
    console.log("🚀 ~ file: NudgeMappingForm.js:307 ~ input:", input);
    return iteration.map((row, mkey) => (
      <Grid
        key={mkey}
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <SelectBox
            name={`nudge_plan[${mkey}].action`}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={input?.nudge_plan?.[mkey]?.action}
            label={"Action"}
            options={[
              { label: "less than", value: "<" },
              { label: "greater than", value: ">" },
              { label: "equal to", value: "=" },
              { label: "not equal to", value: "!=" },
              { label: "less than equal to", value: "<=" },
              { label: "greater than equal to", value: ">=" },
            ]}
          />
        </Grid>
        <Grid item xs={2}>
          <TextBox
            name={`nudge_plan[${mkey}].value`}
            onChange={handleChange}
            label={"Value"}
            defaultValue={input?.nudge_plan?.[mkey]?.value}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={3}>
          <SelectBox
            name={`nudge_plan[${mkey}].type`}
            onChange={handleChange}
            label={"Type"}
            onBlur={handleBlur}
            defaultValue={input?.nudge_plan?.[mkey]?.type}
            options={[
              { label: "One Way", value: "one_way" },
              { label: "Two Way", value: "two_way" },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectBox
            name={`nudge_plan[${mkey}].nudges`}
            onChange={handleChange}
            label={"Nudges"}
            onBlur={handleBlur}
            defaultValue={input?.nudge_plan?.[mkey]?.nudges}
            options={
              input?.nudge_plan?.[mkey]?.type === "one_way"
                ? nudges.map((x) => {
                    let obj = {};
                    obj.label = x.nudge;
                    obj.value = x.nudge_id;
                    return obj;
                  })
                : input?.nudge_plan?.[mkey]?.type === "two_way"
                ? DataPointFollowOption
                : []
            }
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => {
              deleteNudgeMappForm(mkey);
              onRowDelete(mkey);
            }}
            style={{ marginTop: "20px" }}
            size="small"
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    ));
  }
);
