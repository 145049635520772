import { Typography } from "@mui/material";
import { colors } from "../../constant";

export const TitleText = ({ title, subtitle }) => {
  return (
    <div style={{ padding: "8px", fontFamily: "Noto Sans" }}>
      <Typography style={{ fontSize: "16px" }} component="h5" variant="body1">
        {title}
      </Typography>
      <Typography
        style={{ fontSize: "10px" }}
        component="p"
        color={colors.LIGHT_GREY}
        variant="body2"
      >
        {subtitle}
      </Typography>
    </div>
  );
};
