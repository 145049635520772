import { Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Btn, TextBox, SelectBox } from "../../components";
import { useNudge, useSnackbar } from "../../hooks";
import { useParams } from "react-router-dom";
import { ProcessGrapqlError } from "../../App";
export const NudgeForm = ({
  SetOpen,
  channels = [],
  modules = [],
  frequencies = [],
  nudgeTypes = [],
}) => {
  const { diseaseId } = useParams();
  const { useAddNudge, useUpdateNudge, getNudgeEdit, getNudge } = useNudge({
    disease_uuid: diseaseId,
  });
  const { openSnackBar } = useSnackbar();
  const [AddNudge] = useAddNudge;
  const [UpdateNudge] = useUpdateNudge;
  const edit = getNudgeEdit();
  const nudge = getNudge();
  return (
    <>
      <Formik
        initialValues={{
          nudge: edit ? nudge?.nudge : "",
          nudge_type: edit ? nudge?.nudgeType?.uuid : "",
          module_id: edit ? nudge?.module?.uuid : "",
          frequency_id: edit ? nudge?.frequency?.uuid : "",
        }}
        validationSchema={Yup.object().shape({
          nudge: Yup.string().max(1000).required("Nudge is required"),
          nudge_type: Yup.string().required(
            "Nudge type is required is required"
          ),
          module_id: Yup.string().required("Nudge Module is required"),
          frequency_id: Yup.string().required("Range from is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (edit) {
            const {
              frequency_id,
              module_id,
              nudge: content,
              nudge_type,
            } = values;

            UpdateNudge({
              variables: {
                frequency_id,
                module_id,
                nudge: content,
                nudge_type,
                disease_uuid: diseaseId,
                nudge_id: nudge.nudge_id,
              },
              onCompleted: () => {
                setSubmitting(false);
                SetOpen(false);
                openSnackBar({ message: "Successfully Save", type: "success" });
              },
              onError: (err) => {
                ProcessGrapqlError({ ...err, snackbar: openSnackBar });
                setSubmitting(false);
              },
            });
          } else {
            const { frequency_id, module_id, nudge, nudge_type } = values;
            AddNudge({
              variables: {
                frequency_id,
                module_id,
                nudge,
                nudge_type,
                disease_uuid: diseaseId,
              },
              onCompleted: () => {
                setSubmitting(false);
                SetOpen(false);
                openSnackBar({ message: "Successfully Save", type: "success" });
              },
              onError: (err) => {
                ProcessGrapqlError({ ...err, snackbar: openSnackBar });
                setSubmitting(false);
              },
            });
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextBox
                  label={"Nudge"}
                  name={"nudge"}
                  placeholder="Nudge"
                  error={Boolean(touched.nudge && errors.nudge)}
                  helperText={touched.nudge && errors.nudge}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.nudge}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectBox
                  label={"Nudge Type"}
                  name={"nudge_type"}
                  placeholder="module"
                  error={Boolean(touched.nudge_type && errors.nudge_type)}
                  helperText={touched.nudge_type && errors.nudge_type}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.nudge_type}
                  options={nudgeTypes}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectBox
                  label={"Module"}
                  name={"module_id"}
                  placeholder="module"
                  error={Boolean(touched.module_id && errors.module_id)}
                  helperText={touched.module_id && errors.module_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.module_id}
                  options={modules}
                />
              </Grid>

              <Grid item xs={4}>
                <SelectBox
                  label={"Frequency"}
                  name={"frequency_id"}
                  placeholder="Frequency"
                  error={Boolean(touched.frequency_id && errors.frequency_id)}
                  helperText={touched.frequency_id && errors.frequency_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.frequency_id}
                  options={frequencies}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack direction={"row"} spacing={1} justifyContent="flex-end">
                  <Btn
                    onClick={() => {
                      SetOpen(false);
                    }}
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    Close
                  </Btn>
                  <Btn
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Btn>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
