import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  ConfirmDialog,
  PageHeader,
  SelectButton,
  Text,
  TitleText,
} from "../../components";
import { colors } from "../../constant";
import { customTableRowColor } from "../../helpers";
import { useNudgeMapping } from "../../hooks";
import { useParams } from "react-router-dom";
export const NudgeMappingPlanListView = ({
  datapoints = [],
  nudges = [],
  personas = [],
  onClickCreateNP,
  setCurrentNudgeMappingData,
  setEditNudgeMapping,
}) => {
  const { diseaseId } = useParams();
  const { useGetNudgeMappingPlans, useDeleteNudgeMappingPlan } =
    useNudgeMapping({ disease_uuid: diseaseId });

  const [GetNudgeMappingPlans, { data, loading, error }] =
    useGetNudgeMappingPlans;
  personas = personas?.reduce(
    (obj, item) => Object.assign(obj, { [item.uuid]: item.title }),
    {}
  );
  datapoints = datapoints.reduce(
    (obj, item) =>
      Object.assign(obj, { [item.key]: `${item.key} - ${item.label}` }),
    {}
  );
  useEffect(() => {
    GetNudgeMappingPlans({
      variables: { diseaseId: diseaseId },
    });
  }, [GetNudgeMappingPlans]);
  return (
    <>
      <PageHeader
        left={<TitleText title={"Nudges mappings"} />}
        right={
          <IconButton
            onClick={() => {
              onClickCreateNP(true);
            }}
            color="primary"
          >
            <AddOutlinedIcon />
          </IconButton>
        }
      />

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Key</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Label</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Persona</Text>
            </TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            data &&
            data?.GetAllNudgeMappingPlan.map((row, idx) => (
              <TableRows
                key={idx}
                diseaseId={diseaseId}
                row={row}
                idx={idx}
                personas={personas}
                datapoints={datapoints}
                onClickCreateNP={onClickCreateNP}
                setCurrentNudgeMappingData={setCurrentNudgeMappingData}
                setEditNudgeMapping={setEditNudgeMapping}
              />
            ))}
        </TableBody>
      </Table>
    </>
  );
};

const TableRows = ({
  row,
  idx,
  diseaseId,
  personas,
  datapoints,
  setCurrentNudgeMappingData,
  setEditNudgeMapping,
}) => {
  console.log("xxx row:------>", row);
  const { setNudgeMappingEdit, useDeleteNudgeMappingPlan } = useNudgeMapping({
    disease_uuid: diseaseId,
  });
  const [DeleteNudgeMappingPlan] = useDeleteNudgeMappingPlan;
  const [open, setOpen] = React.useState(false);
  const handleDelete = () => {
    DeleteNudgeMappingPlan({ variables: { uuid: row.uuid } });
  };
  return (
    <>
      {open && (
        <ConfirmDialog
          title={"Confirm Delete"}
          discription={"Are you sure once delete can't be undone?"}
          onClose={() => {
            setOpen(false);
          }}
          positive={() => {
            handleDelete();
          }}
          positiveLabel="Yes"
          negativeLabel="No"
        />
      )}
      <TableRow key={row.nudge_id}>
        <TableCell>
          <Text>{datapoints[row.key]}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.label}</Text>
        </TableCell>
        <TableCell component="th" scope="row">
          <Text>
            {" "}
            {personas && personas[row.persona] ? personas[row.persona] : "NA"}
          </Text>
        </TableCell>

        <TableCell>
          <SelectButton
            type="icon"
            onSelect={(item) => {
              switch (item.value) {
                case "delete":
                  {
                    setCurrentNudgeMappingData(row);
                    setOpen(true);
                  }
                  break;
                case "edit":
                  {
                    setCurrentNudgeMappingData(row);
                    setEditNudgeMapping(true);
                  }
                  break;
              }
            }}
            options={[
              { label: "Edit", value: "edit" },
              { label: "Delete", value: "delete" },
            ]}
          >
            <MoreVertOutlinedIcon fontSize="8" />
          </SelectButton>
        </TableCell>
      </TableRow>
    </>
  );
};
