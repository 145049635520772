import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

let cardstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
export const ModalContainer = ({
  children,
  title = "",
  onClose,
  style = {},
}) => {
  const handleClose = () => {
    onClose(false);
  };
  cardstyle = { ...cardstyle, ...style };
  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card style={cardstyle}>
        <CardHeader
          action={
            <IconButton onClick={handleClose} aria-label="settings">
              <CloseIcon />
            </IconButton>
          }
          title={title}
        />

        <CardContent>{children}</CardContent>
      </Card>
    </Modal>
  );
};
