import { Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Btn, TextBox, SelectBox } from "../components";
import { useNudge, useSnackbar } from "../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ProcessGrapqlError } from "../App";
import { useAuth } from "../hooks";
import { AlertHelper, StorageHelper } from "../helpers";

export const LoginForm = ({}) => {
  const navigate = useNavigate();
  const { useSignin } = useAuth({});
  let [GetSignin, { data = {}, loading, error }] = useSignin;
  console.log("xxx data :---->", data);
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().max(1000).required("Email is required"),
            password: Yup.string().min(5).required("Password is required"),
          })}
          onSubmit={(payload) => {
            console.log("xxx coming her :->", payload);
            GetSignin({
              variables: {
                email: payload.email,
                password: payload.password,
              },
              onCompleted: (data) => {
                if (data && data.Signin) {
                  const { Signin: { error, ok, payload:{token,email,user_id} = {} } = {} } = data || {};
                  if(ok){
                    StorageHelper.Set("login", true);
                    // StorageHelper.Set("role", role);
                    StorageHelper.Set("token", token);
                    StorageHelper.Set("email", email);
                    navigate("/app/disease", { replace: true });
                  }else{
                    AlertHelper.error('Password is   wrong')
                  }
                }else{
                  AlertHelper.error('User does not exist')
                }
              },
              onError: (err) => {
                console.log(err);
              },
            });
          }}
        >
          {({
            isSubmitting,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextBox
                    label={"Email"}
                    name={"email"}
                    placeholder="Please enter email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    label={"Password"}
                    name={"password"}
                    placeholder="Enter Password"
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    type={"password"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    {/* <Btn
                    onClick={() => {
                      SetOpen(false);
                    }}
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    Close
                  </Btn> */}
                    <Btn
                      variant="contained"
                      type="submit"
                      // disabled={isSubmitting}
                    >
                      Login
                    </Btn>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
};
