import { Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import {
  Btn,
  InputOptions,
  RadioOptions,
  SelectBox,
  TextBox,
} from "../../../components";
import { useDiseaseArea } from "../../../hooks";
import { useParams } from "react-router-dom";

export const DataPointForm = ({ SetOpen }) => {
  const { diseaseId } = useParams();
  const {
    useAddDataPoint,
    useUpdateDataPoint,
    getDatapointEdit,
    getDatapoint,
    useGetDPOption,
    useGetDPFollowOption,
  } = useDiseaseArea({
    diseaseUuid: diseaseId,
  });

  const [AddDatapoint] = useAddDataPoint;
  const [UpdateDatapoint] = useUpdateDataPoint;
  const [
    GetDPoption,
    { data: { DataPointOption } = { DataPointOption: [] }, loading },
  ] = useGetDPOption;
  const [
    GetDPoptionFollowUp,
    {
      data: { DataPointFollowOption } = {
        DataPointFollowOption: [],
      },
      loadingFP,
    },
  ] = useGetDPFollowOption;
  const edit = getDatapointEdit();
  const datapoint = getDatapoint();

  useEffect(() => {
    GetDPoption({ variables: { diseaseUuid: diseaseId } });
  }, [GetDPoption]);
  useEffect(() => {
    GetDPoptionFollowUp({
      variables: {
        diseaseUuid: diseaseId,
        source: "FollowUp",
        type: "section",
      },
    });
  }, [GetDPoptionFollowUp]);

  if (loading) {
    return <></>;
  }

  if (loadingFP) {
    return <></>;
  }
  return (
    <>
      <Formik
        initialValues={{
          key: edit ? datapoint?.key : "",
          required: edit ? datapoint?.required : false,
          type: edit ? datapoint?.type : "radio",
          scores: edit ? datapoint?.scores : [],
          label: edit ? datapoint?.label : "",
          min: edit ? datapoint?.min : 0,
          max: edit ? datapoint?.max : 0,
          source: edit ? datapoint?.source : "Import",
          datapointType: edit ? datapoint?.datapoint_type : "DataPoint",
        }}
        validationSchema={Yup.object().shape({
          key: Yup.string().max(255).required("Datapoint key is required"),
          type: Yup.string().max(255).required("Type is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (edit) {
            UpdateDatapoint({
              variables: {
                uuid: datapoint?.uuid,
                key: datapoint?.key,
                required: values?.required,
                type: values?.type,
                scores: values?.scores,
                label: values?.label,
                min: values.min,
                max: values.max,
                values: values?.type === "radio" ? values?.scores : [],
                diseaseUuid: diseaseId,
                source: values.source,
                datapointType: values.datapointType,
                Id: datapoint?.id,
              },
              onCompleted: () => {
                setSubmitting(false);
                SetOpen(false);
              },
              onError: () => {
                setSubmitting(false);
              },
            });
          } else {
            AddDatapoint({
              variables: {
                key: values?.key,
                required: values?.required,
                type: values?.type,
                scores: values?.scores,
                label: values?.label,
                values: values?.type === "radio" ? values?.scores : [],
                diseaseUuid: diseaseId,
                min: values.min,
                max: values.max,
                source: values.source,
                datapointType: values.datapointType,
              },
              onCompleted: () => {
                setSubmitting(false);
                SetOpen(false);
              },
              onError: () => {
                setSubmitting(false);
              },
            });
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextBox
                  label={"Datapoint Key"}
                  name={"key"}
                  placeholder="Datapoint Key"
                  error={Boolean(touched.key && errors.key)}
                  helperText={touched.key && errors.key}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.key}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  label={"Type"}
                  name={"type"}
                  placeholder="Type"
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.type}
                  options={[
                    { label: "Input", value: "input" },
                    { label: "Radio", value: "radio" },
                    { label: "Section", value: "section" },
                    { label: "Label", value: "label" },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  label={"Source"}
                  name={"source"}
                  placeholder="Source"
                  error={Boolean(touched.source && errors.source)}
                  helperText={touched.source && errors.source}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.source}
                  options={[
                    { label: "Import", value: "Import" },
                    { label: "Patient", value: "Patient" },
                    { label: "FollowUp", value: "FollowUp" },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectBox
                  label={"Datapoint Type"}
                  name={"datapointType"}
                  placeholder="Datapoint Type"
                  error={Boolean(touched.datapointType && errors.datapointType)}
                  helperText={touched.datapointType && errors.datapointType}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.datapointType}
                  options={[
                    { label: "DataPoint", value: "DataPoint" },
                    { label: "Nudge", value: "Nudge" },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  label={"Question"}
                  name={"label"}
                  placeholder="Enter your question"
                  error={Boolean(touched.label && errors.label)}
                  helperText={touched.label && errors.label}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.label}
                />
              </Grid>
              {values?.type === "radio" && (
                <Grid item xs={12}>
                  <RadioOptions
                    name="scores"
                    label="Score Options"
                    value={values.scores}
                    datapointOption={DataPointFollowOption}
                    onChangeOptionsValue={handleChange}
                  />
                </Grid>
              )}
              {values?.type === "input" && (
                <Grid item xs={12}>
                  <InputOptions
                    name="scores"
                    label="Score Options"
                    value={values.scores}
                    onChangeOptionsValue={handleChange}
                    datapointOption={DataPointOption}
                    dataPointFollowUpOption={DataPointFollowOption}
                  />
                </Grid>
              )}
            </Grid>

            <Stack direction={"row"} spacing={1} justifyContent="flex-end">
              <Btn
                onClick={() => {
                  SetOpen(false);
                }}
                variant="outlined"
                disabled={isSubmitting}
              >
                Close
              </Btn>
              <Btn variant="contained" type="submit" disabled={isSubmitting}>
                Save
              </Btn>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
