import { Close } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { debounce, isEmpty, values } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Btn } from "../global/Btn";
import { SelectBox } from "../global/SelectBox";
import { TextBox } from "../global/TextBox";

export const InputOptions = ({
  name,
  value = [],
  datapointOption,
  dataPointFollowUpOption,
  onChangeOptionsValue = () => {},
}) => {
  console.log("🚀 ~ file: InputOptions.js:23 ~ value:", value);
  let arr = [];
  const obj = value.reduce((obj, row, index) => {
    obj[index.toString()] = row;
    arr.push(index.toString());
    return obj;
  }, {});
  const [optionCount, setOptionCount] = useState(arr);
  const [values, setInputs] = useState(value);
  const handleChange = useCallback((row, idx) => {
    let arr = [...values];
    arr[idx] = row;
    setInputs(arr);
    onChangeOptionsValue({
      type: "select",
      target: { value: arr, id: name, name: name },
    });
  }, []);

  const handleDelete = useCallback((idx) => {
    const arr = [...values];
    const counts = [...optionCount];
    const newCounts = counts.filter((row) => row !== idx.toString());
    const newArr = arr.filter((row, i) => idx !== i);
    setOptionCount(newCounts);
    setInputs(newArr);
    onChangeOptionsValue({
      type: "select",
      target: { value: newArr, id: name, name: name },
    });
  }, []);
  return (
    <>
      <Grid container>
        {optionCount.map((row, idx) => {
          const arr = (values?.[idx]?.values ?? []).reduce((arr, row, idx) => {
            arr.push(idx.toString());
            return arr;
          }, []);
          return (
            <>
              <SingleInputOptions
                onChange={(val) => {
                  console.log("VAL", val);
                  handleChange(val, idx);
                }}
                handlekeyDelete={() => {
                  handleDelete(idx);
                }}
                objs={values?.[idx]}
                datapointOption={datapointOption}
                dataPointFollowUpOption={dataPointFollowUpOption}
                key={row}
                counts={arr}
              />
            </>
          );
        })}

        <Grid item xs={12}>
          <Btn
            onClick={() => {
              setOptionCount((prevState) => [
                ...prevState,
                (prevState.length + 1).toString(),
              ]);
            }}
          >
            Add Condition
          </Btn>
        </Grid>
      </Grid>
    </>
  );
};

const SingleInputOptions = ({
  objs = { then: [] },
  datapointOption,
  dataPointFollowUpOption,
  onChange = () => {},
  handlekeyDelete = () => {},
}) => {
  const { arr, obj } = useMemo(() => {
    let arr = [];
    const _obj = objs?.then.reduce((obj, row, index) => {
      obj[index.toString()] = row;
      arr.push(index.toString());
      return obj;
    }, {});
    return { arr, obj: _obj };
  }, [objs]);
  console.log("🚀 ~ file: InputOptions.js:104 ~ obj ~ obj:", obj);

  const [optionCount, setOptionCount] = useState(arr);

  const [itemsObj, setItemsObj] = useState(obj);
  const [inputs, setInputs] = useState({
    condition: objs?.if ?? false,
    datapoint: objs?.key ?? "",
    mapValue: objs?.value ?? "",
  });
  const { condition, datapoint, mapValue } = inputs;
  // useEffect(() => {
  //   console.log("COMPARE 1", JSON.stringify(obj));
  //   console.log("COMPARE 2", JSON.stringify(itemsObj));
  //   if (isEmpty(itemsObj)) return;
  //   if (JSON.stringify(obj) === JSON.stringify(itemsObj)) return;

  //   onChange({
  //     if: condition,
  //     key: datapoint,
  //     value: mapValue,
  //     then: values(itemsObj),
  //   });
  // }, [itemsObj, onChange]);

  const onChangeComplete = useCallback(
    (val, index) => {
      setItemsObj((prevState) => {
        const newObj = { ...prevState, [index]: val };
        console.log(
          "🚀 ~ file: InputOptions.js:153 ~ setItemsObj ~ prevState:",
          prevState
        );
        console.log(
          "🚀 ~ file: InputOptions.js:146 ~ setItemsObj ~ newObj:",
          newObj
        );
        onChange({
          if: condition,
          key: datapoint,
          value: mapValue,
          then: values(newObj),
        });

        return { ...prevState, [index]: val };
      });
    },

    [itemsObj, condition, datapoint, mapValue]
  );

  const handleChange = useCallback(
    (e) => {
      setInputs((prevState) => {
        const newobj = { ...prevState, [e.target.name]: e.target.value };
        onChange({
          if: newobj?.condition,
          key: newobj?.datapoint,
          value: newobj?.mapValue,
          then: values(itemsObj),
        });
        return newobj;
      });
    },
    [condition, datapoint, mapValue, itemsObj]
  );

  const onDelete = useCallback(
    (index) => {
      let obj = itemsObj;

      delete obj[index];

      setItemsObj({ ...obj });
      let arr = [...optionCount];
      const newarr = arr.filter((row) => row !== index);
      setOptionCount(newarr);
    },

    [itemsObj]
  );
  const onChangHandler = useRef(debounce(onChangeComplete, 500), []).current;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SelectBox
            name={"condition"}
            onChange={handleChange}
            label={"Has Condition"}
            value={condition}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectBox
            name={"datapoint"}
            disabled={!condition}
            onChange={handleChange}
            value={datapoint}
            label={"Datapoint"}
            options={datapointOption}
          />
        </Grid>
        <Grid item xs={3}>
          <TextBox
            name={"mapValue"}
            disabled={!condition}
            onChange={handleChange}
            value={mapValue}
            label={"Value"}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={handlekeyDelete}
            style={{ marginTop: "20px" }}
            size="small"
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid style={{ marginLeft: "50px" }} item xs={12}>
          {itemsObj &&
            optionCount.map((row, idx) => (
              <>
                <RadioItem
                  key={row}
                  onItemChange={(obj) => {
                    onChangHandler(obj, row);
                  }}
                  onDelete={() => {
                    onDelete(row);
                  }}
                  datapointOption={datapointOption}
                  dataPointFollowUpOption={dataPointFollowUpOption}
                  condition={itemsObj[row]?.condition}
                  value={itemsObj[row]?.value}
                  score={itemsObj[row]?.score}
                  action={itemsObj[row]?.action}
                />
              </>
            ))}
          <Btn
            onClick={() => {
              setOptionCount((prevState) => [
                ...prevState,
                (prevState.length + 1).toString(),
              ]);
            }}
          >
            Add Options
          </Btn>
        </Grid>
      </Grid>
    </>
  );
};

const RadioItem = React.memo(
  ({
    onItemChange,
    condition = "",
    value = "",
    score = 0,
    action = "",
    onDelete,
    datapointOption = [],
    dataPointFollowUpOption = [],
  }) => {
    const [input, setInput] = useState({
      condition: condition,
      value: value,
      score: score,
      action: action,
    });
    const handleChange = (e) => {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    };

    useEffect(() => {
      if (!isEmpty(input)) {
        onItemChange(input);
      }
    }, [input]);
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={3}>
          <SelectBox
            name={"condition"}
            onChange={handleChange}
            value={condition}
            label={"Condition"}
            options={[
              { label: "less than", value: "<" },
              { label: "greater than", value: ">" },
              { label: "equal to", value: "=" },
              { label: "not equal to", value: "!=" },
              { label: "less than equal to", value: "<=" },
              { label: "greater than equal to", value: ">=" },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <TextBox
            name={"value"}
            onChange={handleChange}
            value={input?.value}
            label={"Value"}
          />
        </Grid>
        <Grid item xs={2}>
          <TextBox
            type={"number"}
            name={"score"}
            onChange={handleChange}
            value={input?.score}
            label={"Score"}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectBox
            name={"action"}
            onChange={handleChange}
            value={input?.action}
            label={"Action"}
            options={dataPointFollowUpOption}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={onDelete}
            style={{ marginTop: "20px" }}
            size="small"
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
);
