import { gql, useLazyQuery, useMutation } from "@apollo/client";
const GET_DATAPOINT = gql`
  query Query($diseaseUuid: String!) {
    GetAllDataPoints(disease_uuid: $diseaseUuid) {
      disease_uuid
      uuid
      label
      key
      required
      type
      values
      scores
      min
      max
      next
      datapoint_type
      source
    }
  }
`;

const GET_DATA_DICTIONARY = gql`
  query GetDataDictionaries($reference: String!) {
    GetDataDictionaries(reference: $reference) {
      key
      reference
      values
    }
  }
`;
const ADD_DATA_DICTIONARY = gql`
  mutation Mutation($inputs: [DictionaryInput]) {
    AddDataDictionaries(inputs: $inputs) {
      key
      reference
      values
    }
  }
`;
export const useDataDictionary = () => {
  const useGetDatapoints = useLazyQuery(GET_DATAPOINT);
  const useAddDataDictionaries = useMutation(ADD_DATA_DICTIONARY);
  const useGetDataDictionaries = useLazyQuery(GET_DATA_DICTIONARY);
  return { useGetDatapoints, useAddDataDictionaries, useGetDataDictionaries };
};
