export const URLS = {
  LOGIN: "login",
  MAIN: "landing",
  MANAGE_QUESTION: "questions",
  DISEASE: "disease",
  NUDGE: "nudge",
  IMPORT: "import",
  DATA_DICTIONARY: "dictionary",
  NUDGE_MAPPING: "nudge_mapping",
};
