import { NudgeMappingContextProvider } from "../../hooks";
import { NudgeMappingArea } from "./NudgeMappingArea";
export const NudgeMappingContainer = ({ componentId }) => {
  return (
    <>
      <NudgeMappingContextProvider>
        <NudgeMappingArea componentId={componentId} />
      </NudgeMappingContextProvider>
    </>
  );
};
