import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { colors } from "../../constant";
import { TextBox } from "../global/TextBox";
import { Text } from "../global/Text";

export const QuestionCard = ({ item }) => {
  return (
    <>
      <Card>
        <CardHeader
          style={{ backgroundColor: colors.GREY_BG }}
          // action={
          //   <Button
          //     size="small"
          //     variant="text"
          //     startIcon={<SaveOutlinedIcon />}
          //   >
          //     Save
          //   </Button>
          // }
          title={
            <Typography variant="body2" color="text.primary">
              Question {item?.id}
            </Typography>
          }
        />

        <CardContent>
          <Grid container direction={"column"}>
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary">
                {item?.label}
              </Typography>
            </Grid>

            {item?.type === "input" && (
              <Grid item xs={12}>
                <TextBox fullWidth placeholder={""} label={""} />
              </Grid>
            )}
            {item?.type === "radio" &&
              item?.values?.map((row, idx) => (
                <>
                  <Grid item xs={12}>
                    <Text>{row?.name}</Text>
                  </Grid>
                </>
              ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
