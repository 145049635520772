import { Subject } from "rxjs";

const alert = new Subject();
export const alertService = {
  sendMessage: (message, type, status) =>
    alert.next({ message: message, type: type, status: status }),
  clearMessages: () => alert.next(),
  getMessage: () => alert.asObservable(),
  unSubsribe: () => alert.unsubscribe(),
};

const rx = new Subject();
export const rxjs = {
  sendMessage: (message, type) => rx.next({ message: message, type: type }),
  clearMessages: () => rx.next(),
  getMessage: () => rx.asObservable(),
  unSubsribe: () => rx.unsubscribe(),
};
