import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ConfirmDialog, Text } from "../../components";
import { colors } from "../../constant";
import { usePatientPersona } from "../../hooks";
export const PatientPersonaListView = () => {
  const { diseaseId } = useParams();
  const { useGetPatientPersonaList } = usePatientPersona({
    disease_uuid: diseaseId,
  });

  const [
    GetPatientPersonaListByDiseaseId,
    {
      data: { GetPatientPersonaListByDiseaseId: patientList = [] } = {},
      loading,
      error,
    },
  ] = useGetPatientPersonaList;

  useEffect(() => {
    GetPatientPersonaListByDiseaseId({
      variables: { disease_uuid: diseaseId },
    });
  }, [GetPatientPersonaListByDiseaseId]);
  console.log("xxx data111999:-->", patientList);
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Patient ID</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Persona title</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Score</Text>
            </TableCell>

            <TableCell>
              <Text color={colors.TEXT_GREY}> Nudge Stat</Text>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            patientList.map((row, idx) => (
              <TableRows key={idx} diseaseId={diseaseId} row={row} idx={idx} />
            ))}
        </TableBody>
      </Table>
    </>
  );
};

const TableRows = ({ row, idx, diseaseId }) => {
  console.log("xxx rows :->", row);
  const {
    setPatientPersona,
    setPatientPersonaEdit,
    setOpenPatientPersonaModal,
  } = usePatientPersona({
    disease_uuid: diseaseId,
  });
  const [open, setOpen] = React.useState(false);
  const handleDelete = () => {
    // DeletePatientPersona({ variables: { nudge_id: row.nudge_id } });
  };
  return (
    <>
      {open && (
        <ConfirmDialog
          title={"Confirm Delete"}
          discription={"Are you sure once delete can't be undone?"}
          onClose={() => {
            setOpen(false);
          }}
          positive={() => {
            // handleDelete();
          }}
          positiveLabel="Yes"
          negativeLabel="No"
        />
      )}
      <TableRow key={row.nudge_id}>
        <TableCell component="th" scope="row">
          <Text> {row.unique_hid}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.title}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.score}</Text>
        </TableCell>

        <TableCell>
          <Text>{row.nudge_stat ? row.nudge_stat : "NA"}</Text>
        </TableCell>
        <TableCell>
          {/* <SelectButton
            type="icon"
            onSelect={(item) => {
              switch (item.value) {
                case "delete":
                  {
                    setOpen(true);
                  }
                  break;
                case "edit":
                  {
                    setOpenPatientPersonaModal({
                      open: true,
                      edit: true,
                      nudge: row,
                    });
                  }
                  break;
              }
            }}
            options={[
              { label: "Edit", value: "edit" },
              { label: "Delete", value: "delete" },
            ]}
          >
            <MoreVertOutlinedIcon fontSize="8" />
          </SelectButton> */}
        </TableCell>
      </TableRow>
    </>
  );
};
