import { colors } from "../../constant";

export const PageHeader = ({ left, right }) => {
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: colors.WHITE,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
};
