import { useRef, useState, useEffect } from "react";
import { useNudgeMapping } from "../../hooks";
import { NudgeMappingForm } from "./NudgeMappingForm";
import { AnswerMappingListView } from "./AnswerMappingListView";
import { NudgeMappingPlanListView } from "./NudgeMappingPlanListView";
import { useParams } from "react-router-dom";
import { DefaultContainer } from "../../components";

export const NudgeMappingArea = ({ componentId }) => {
  const { diseaseId: disease_uuid } = useParams();

  const [isCreateNudgeMapping, setCreateNudgeMapping] = useState(false);
  const [currentNudgeMappingData, setCurrentNudgeMappingData] = useState({});
  const [isEditNudgeMapping, setEditNudgeMapping] = useState(false);

  const onClickCreateNP = (val) => {
    console.log("xxx val:->", val);
    setCreateNudgeMapping(val);
  };

  const {
    useGetNudgeMappingPlans,
    useGetNudgePersona,
    useGetDataPoints,
    useGetAnswerMapping,
    useGetNudgeMappingPlan
  } = useNudgeMapping({
    diseaseUuid: disease_uuid,
  });

  const [GetNudgeMappingPlans, { data: nudgeMappingData, loading, error }] =
    useGetNudgeMappingPlans;

    const [
      GetNudgeMappingPlan,
      { data: nudgeMappingPlanData, nudgePlanLoading, nudgePlanError },
    ] = useGetNudgeMappingPlan;
    console.log('xxx nudgeMappingPlanData:-> ',nudgeMappingPlanData)

  useEffect(() => {
    GetNudgeMappingPlans({
      variables: { diseaseId: disease_uuid },
    });
    GetNudgePersona({ variables: { diseaseUuid: disease_uuid } });
    GetDatapoints({ variables: { disease_uuid } });
    GetAnswerMapping({ variables: { disease_uuid } });
  }, [GetNudgeMappingPlans]);

  const [
    GetNudgePersona,
    { data: nudgeData, loading: nudgeLoading, error: nudgeError },
  ] = useGetNudgePersona;
  const [
    GetDatapoints,
    { data: datapoints, loading: dataloading, error: dataerror },
  ] = useGetDataPoints;
  const [
    GetAnswerMapping,
    { data: answerMappings, loading: amLoading, error: amError },
  ] = useGetAnswerMapping;

  switch (componentId) {
    case 1:
      return isCreateNudgeMapping || isEditNudgeMapping ? (
        <NudgeMappingForm
          onClickCreateNP={onClickCreateNP}
          setEditNudgeMapping={setEditNudgeMapping}
          currentNudgeMappingData={currentNudgeMappingData}
          isEditNudgeMapping={isEditNudgeMapping}
          setCurrentNudgeMappingData={setCurrentNudgeMappingData}
        />
      ) : nudgeMappingData?.GetAllNudgeMappingPlan.length > 0 ? (
        <NudgeMappingPlanListView
          datapoints={datapoints?.GetAllDataPoints}
          nudges={nudgeData?.GetAllNudges}
          personas={nudgeData?.GetAllPersonas}
          onClickCreateNP={onClickCreateNP}
          setCurrentNudgeMappingData={setCurrentNudgeMappingData}
          setEditNudgeMapping={setEditNudgeMapping}
        />
      ) : (
        <>
          <DefaultContainer onClickCreateNP={onClickCreateNP} />
        </>
      );

      break;

    case 2:
      return isEditNudgeMapping ? (
        <NudgeMappingForm
          onClickCreateNP={onClickCreateNP}
          setEditNudgeMapping={setEditNudgeMapping}
          currentNudgeMappingData={currentNudgeMappingData}
          isEditNudgeMapping={isEditNudgeMapping}
          setCurrentNudgeMappingData={setCurrentNudgeMappingData}
        />
      ) : (
        <AnswerMappingListView
          datapoints={datapoints?.GetAllDataPoints}
          answerMappings={answerMappings?.GetAnswerMapping}
          nudges={nudgeData?.GetAllNudges}
          personas={nudgeData?.GetAllPersonas}
          setEditNudgeMapping={setEditNudgeMapping}
          currentNudgeMappingData={currentNudgeMappingData}
          isEditNudgeMapping={isEditNudgeMapping}
          setCurrentNudgeMappingData={setCurrentNudgeMappingData}
          GetNudgeMappingPlan={GetNudgeMappingPlan}
        />
      );
      break;

    default:
      break;
  }
};
