import { AppBar, Container, IconButton, Toolbar } from "@mui/material";
import { BreadCrumb } from "./global/BreadCrumb";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { colors } from "../constant";
import { history } from "../helpers";

export const SecondaryNavBar = ({ breadcrum = [""], right = <></> }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: colors.WHITE,
          borderBottom: `1px solid ${colors.GREY_BG}`,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar style={{ minHeight: "48px" }} disableGutters>
            <IconButton
              onClick={() => {
                history.back();
              }}
              size="large"
              edge="start"
              sx={{ mr: 2 }}
            >
              <ArrowBackOutlinedIcon />
            </IconButton>
            <BreadCrumb data={breadcrum} />
            <div style={{ flexGrow: 1 }}></div>
            {right}
          </Toolbar>
        </Container>
      </div>
    </>
  );
};
