import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GridLayout, SecondaryNavBar } from "../components";
import { QuestionnaireContextProvider, useDiseaseList } from "../hooks";
import { Questions } from "./Questionnaire/Questions";
import { QuestionPanel } from "./Questionnaire/QuestionsPanel";

export const Landing = () => {
  const { diseaseId } = useParams();
  const navigate = useNavigate();

  const { useGetDisease } = useDiseaseList({});
  let [
    GetDisease,
    {
      data: { GetDisease: diseaseDetails = [] } = {},
      disease_loading,
      disease_error,
    },
  ] = useGetDisease;
  const diseaseLabel = diseaseDetails?.name ? diseaseDetails?.name : "NA";
  useEffect(() => {
    GetDisease({ variables: { uuid: diseaseId } });
  }, [GetDisease]);
  return (
    <>
      <QuestionnaireContextProvider>
        <SecondaryNavBar
          breadcrum={[
            { label: diseaseLabel },
            { label: "Manage Questionnaire" },
          ]}
        />
        <GridLayout
          leftContent={<Questions />}
          rightContent={<QuestionPanel />}
        />
      </QuestionnaireContextProvider>
    </>
  );
};
