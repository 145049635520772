import { gql, useLazyQuery } from "@apollo/client";
import React, { createContext } from "react";
const PatientPersonaContext = createContext();
export const PatientPersonaContextProvider = ({ children }) => {
  const [context, setContextData] = React.useState({});
  const setContext = React.useCallback((data) => {
    setContextData(data);
  }, []);
  const values = React.useMemo(
    () => ({ context, setContext }),
    [context, setContext]
  );

  return (
    <PatientPersonaContext.Provider value={values}>
      {children}
    </PatientPersonaContext.Provider>
  );
};

const GET_PATIENT_PERSONA_LIST = gql`
  query Query($disease_uuid: String!) {
    GetPatientPersonaListByDiseaseId(diseaseId: $disease_uuid) {
      nudge_stat
      score
      title
      unique_hid
    }
  }
`;

export const usePatientPersona = ({ disease_uuid = "" }) => {
  const { context, setContext } = React.useContext(PatientPersonaContext);

  const setPatientPersona = React.useCallback((nudge) => {
    setContext({ ...context, nudge: nudge });
  }, []);

  const getPatientPersona = () => {
    return context?.nudge;
  };

  const setPatientPersonaEdit = React.useCallback((edit) => {
    setContext({ ...context, nudgeEdit: edit });
  }, []);
  const getPatientPersonaEdit = () => {
    return context?.nudgeEdit;
  };

  const setOpenPatientPersonaModal = React.useCallback(
    ({ open, edit, nudge }) => {
      setContext({
        ...context,
        nudgeModelOpen: open,
        nudgeEdit: edit,
        nudge: nudge,
      });
    },
    []
  );

  const useGetPatientPersonaList = useLazyQuery(GET_PATIENT_PERSONA_LIST);

  return {
    useGetPatientPersonaList,

    setPatientPersona,
  };
};
