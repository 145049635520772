import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialog, SelectButton, Text } from "../../../components";
import { colors, URLS } from "../../../constant";
import { customTableRowColor } from "../../../helpers";
import { useDiseaseArea } from "../../../hooks";
export const DatapointListView = () => {
  const { diseaseId } = useParams();
  const { useGetDatapoints } = useDiseaseArea({ diseaseUuid: diseaseId });

  const [GetDataPoints, { data, loading, error }] = useGetDatapoints;
  useEffect(() => {
    GetDataPoints({ variables: { diseaseUuid: diseaseId } });
  }, [GetDataPoints]);
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Data Point</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Source</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Type</Text>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            !error &&
            data &&
            data?.GetAllDataPoints.map((row, idx) => (
              <TableRows key={idx} diseaseId={diseaseId} row={row} idx={idx} />
            ))}
        </TableBody>
      </Table>
    </>
  );
};

const TableRows = ({ row, idx, diseaseId }) => {
  const navigate = useNavigate();
  const { useDeleteDatapoint, setOpenDatapointModal } = useDiseaseArea({
    diseaseUuid: diseaseId,
  });
  const [Delete] = useDeleteDatapoint;
  const [open, setOpen] = React.useState(false);
  const handleDelete = () => {
    Delete({ variables: { uuid: row.uuid } });
  };
  return (
    <>
      {open && (
        <ConfirmDialog
          title={"Confirm Delete"}
          discription={"Are you sure once delete can't be undone?"}
          onClose={() => {
            setOpen(false);
          }}
          positive={() => {
            handleDelete();
          }}
          positiveLabel="Yes"
          negativeLabel="No"
        />
      )}
      <TableRow
        key={row.uuid}
        style={{ backgroundColor: customTableRowColor(idx) }}
      >
        <TableCell component="th" scope="row">
          <Text> {row.key}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.source}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.datapoint_type}</Text>
        </TableCell>
        <TableCell>
          <SelectButton
            type="icon"
            onSelect={(item) => {
              switch (item.value) {
                case "delete":
                  {
                    setOpen(true);
                  }
                  break;
                case "edit":
                  {
                    setOpenDatapointModal({
                      open: true,
                      edit: true,
                      data: row,
                    });
                  }
                  break;
                case "dic":
                  {
                    navigate(
                      "/app/" +
                        URLS.DATA_DICTIONARY +
                        "/" +
                        row.uuid +
                        "/" +
                        diseaseId
                    );
                  }
                  break;
              }
            }}
            options={[
              { label: "Edit", value: "edit" },
              { label: "Delete", value: "delete" },
              { label: "Dictionary", value: "dic" },
            ]}
          >
            <MoreVertOutlinedIcon fontSize="8" />
          </SelectButton>
        </TableCell>
      </TableRow>
    </>
  );
};
