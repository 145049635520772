import { Alert, Snackbar, styled } from "@mui/material";
import { Outlet } from "react-router-dom";

import { colors } from "../constant";
import { NavBar } from "./NavBar";
import { useSnackbar } from "../hooks";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: `${colors.MAIN_BG}`,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 70,
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "92vh",
  overflow: "auto",
});

export const DashboardLayout = () => {
  const { context, closeSnackBar } = useSnackbar();
  const { message = "", open = false, type = "info" } = context;
  return (
    <DashboardLayoutRoot>
      <NavBar />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={() => {
          closeSnackBar();
        }}
        autoHideDuration={6000}
        message="I love snacks"
        key={"snack"}
      >
        <Alert severity={type}>{message}</Alert>
      </Snackbar>
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};
