import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { createContext } from "react";

const ImportAreaContext = createContext();

export const ImportAreaContextProvider = ({ children }) => {
  const [context, setContextData] = React.useState({});
  const setContext = React.useCallback((data) => {
    setContextData((prevState) => ({ ...prevState, ...data }));
  }, []);
  const values = React.useMemo(
    () => ({ context, setContext }),
    [context, setContext]
  );
  return (
    <ImportAreaContext.Provider value={values}>
      {children}
    </ImportAreaContext.Provider>
  );
};

export const useImportArea = () => {
  const { context, setContext } = React.useContext(ImportAreaContext);
  const setDataPointSelection = React.useCallback((dpFormData) => {
    setContext({ ...context, dpFormData: dpFormData });
  }, []);
  const GET_DATAPOINT = gql`
    query Query(
      $diseaseUuid: String!
      $datapointType: String
      $source: String
    ) {
      GetAllDataPoints(
        disease_uuid: $diseaseUuid
        datapoint_type: $datapointType
        source: $source
      ) {
        disease_uuid
        uuid
        label
        key
        required
        type
        values
        scores
        min
        max
        next
        id
      }
    }
  `;
  const useGetDatapoints = useLazyQuery(GET_DATAPOINT);

  const setExcelData = React.useCallback((excelData) => {
    setContext({ ...context, excelData: excelData });
  }, []);

  // const setExcelData = (excelData) => {
  //   console.log("excelData +++", excelData)
  //   setContext({ ...context, excelData: excelData });
  // }

  // const setExcelDataPoint = React.useCallback((excelDataPoint) => {
  //   setContext({ ...context, excelDataPoint: excelDataPoint });
  // }, []);

  const getExcelData = () => {
    return context?.excelData || [];
  };

  const getExcelDataPoint = () => {
    return context?.excelDataPoint;
  };

  const clearImportData = () => {
    setContext({});
  };

  const setExcelDataPoint = React.useCallback((keys) => {
    let res = keys.map((x) => {
      let obj = {};
      obj.label = x;
      obj.value = x;
      return obj;
    });
    setContext({ ...context, excelDataPoint: res });
  }, []);

  const setImportModalOpen = React.useCallback((open) => {
    setContext({ ...context, importModalOpen: open });
  }, []);

  return {
    getExcelData,
    setImportModalOpen,
    setExcelData,
    setDataPointSelection,
    clearImportData,
    setExcelDataPoint,
    getExcelDataPoint,
    openImportModal: context?.importModalOpen,
    useAddBulkPatientDataPoint,
    useGetDatapoints,
  };
};

const ADD_BULK_PATIENT = gql`
  mutation Mutation($csvData: [JSON], $dpSchema: JSON, $diseaseId: String!) {
    AddBulkPatient(
      csvData: $csvData
      dpSchema: $dpSchema
      disease_id: $diseaseId
    )
  }
`;

const useAddBulkPatientDataPoint = () => useMutation(ADD_BULK_PATIENT);
