import { Stack } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Btn, TextBox, SelectBox } from "../../../components";
import { useDisease } from "../../../hooks";
export const DiseaseForm = ({ SetOpen }) => {
  const { useAddDisease, useUpdateDisease, getDiseaseEdit, getDisease } =
    useDisease({});

  const [AddDisease] = useAddDisease;
  const [UpdateDisease] = useUpdateDisease;
  const edit = getDiseaseEdit();
  const disease = getDisease();
  console.log("xxx disease:--->", disease);
  return (
    <>
      <Formik
        initialValues={{
          description: edit ? disease?.description : "",
          name: edit ? disease?.name : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("name is required"),
          description: Yup.string().required("description from is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (edit) {
            const { name, description } = values;
            console.log("xxx data to be sen t :-->", {
              name,
              description,
              uuid: disease.uuid,
            });

            UpdateDisease({
              variables: {
                name,
                description,
                uuid: disease.uuid,
              },
              onCompleted: () => {
                setSubmitting(false);
                SetOpen(false);
              },
              onError: () => {
                setSubmitting(false);
              },
            });
          } else {
            console.log("xxx values:-->", values);
            const { name, description } = values;
            AddDisease({
              variables: {
                name,
                description,
              },
              onCompleted: () => {
                setSubmitting(false);
                SetOpen(false);
              },
              onError: () => {
                setSubmitting(false);
              },
            });
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextBox
                label={"Name"}
                name={"name"}
                placeholder="name"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />

              <Stack direction={"row"} spacing={1}>
                <TextBox
                  label={"Description"}
                  name={"description"}
                  placeholder="description"
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                />
              </Stack>
              <Stack direction={"row"} spacing={1} justifyContent="flex-end">
                <Btn
                  onClick={() => {
                    SetOpen(false);
                  }}
                  variant="outlined"
                  disabled={isSubmitting}
                >
                  Close
                </Btn>
                <Btn variant="contained" type="submit" disabled={isSubmitting}>
                  Save
                </Btn>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
