import { ThemeProvider, createTheme } from "@mui/material/styles";
import { colors } from "./constant";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { CustomSnackBar } from "./components/global/CustomSnackBar";
const theme = createTheme({
  palette: {
    primary: {
      main: colors.PRIMARY,
    },
  },
});

function App() {
  const routing = useRoutes(routes);
  return (
    <>
      <ThemeProvider theme={theme}>
        {routing}
        <CustomSnackBar />
      </ThemeProvider>
    </>
  );
}

export default App;

export const ProcessGrapqlError = ({
  snackbar,
  graphQLErrors,
  networkError,
  ...rest
}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions.code === "UNAUTHENTICATED") {
      }
      snackbar({ message: message, type: "error" });
    });

  if (rest) {
    snackbar({ message: rest?.message ?? JSON.stringify(rest), type: "error" });
    console.log(rest);
  }
};
