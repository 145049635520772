import { Button, IconButton, MenuItem } from "@mui/material";
import React from "react";
import { StyledMenu } from "./CustomMenu";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
export const SelectButton = ({
  children,
  options,
  type = "button",
  icon = <AddIcon />,
  onSelect,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (row) => {
    onSelect(row);
    handleClose();
  };
  return (
    <>
      <div>
        {type === "button" ? (
          <Button
            style={{ textTransform: "capitalize" }}
            variant="text"
            disableElevation
            onClick={handleClick}
            startIcon={icon}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {children}
          </Button>
        ) : (
          <IconButton onClick={handleClick} size="small">
            {children}
          </IconButton>
        )}
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options &&
            options.map((row, idx) => (
              <MenuItem
                key={idx}
                onClick={() => {
                  handleSelect(row);
                }}
                disableRipple
              >
                {row?.label}
              </MenuItem>
            ))}
          {/* 
          <Divider sx={{ my: 0.5 }} /> */}
        </StyledMenu>
      </div>
    </>
  );
};
