import { useRef, useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { Btn } from "./global/Btn";
import Nudge from "../assets/image/nudge.png";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Text } from "./global/Text";
export const DefaultContainer = ({onClickCreateNP}) => {
  const style = {
    display: "flex",
    justifyContent: "center",
    height: "80vh",
    alignItems: "center",
  };
  console.log('xxx onClickCreateNP:-.>',onClickCreateNP)
  return (<>
    <div style={style}>
      <Stack
        style={{
          width: "300px",
        }}
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <img style={{ width: "80px" }} src={Nudge} />
        <Btn
          onClick={() => onClickCreateNP(true)}
          size="small"
          startIcon={<AddOutlinedIcon />}
          variant="contained"
        >
          Create Nudge Plan
        </Btn>
        <Text style={{ textAlign: "center" }} size={12}>
          You haven’t created any nudge plan yet. Click on the button above to
          create and design a new nudge plan
        </Text>
      </Stack>
    </div>
  </>)
};
