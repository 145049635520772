import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { ModalContainer, PageHeader, TitleText } from "../../components";
import { useDiseaseArea } from "../../hooks";
import { DataPointForm } from "./datapoint/DataPointForm";
import { DatapointListView } from "./datapoint/DatapointListView";
import { PersonaForm } from "./persona/PersonaForm";
import { PersonaListView } from "./persona/PersonaListView";
export const DiseaseLeftArea = () => {
  return (
    <>
      <Stack spacing={3}>
        <PersonaContainer />
        <DataPointContainer />
      </Stack>
    </>
  );
};

const PersonaContainer = () => {
  const { openPersonaModal, setOpenPersonaModal } = useDiseaseArea({
    diseaseUuid: "8ca3b0cd-748f-49a2-8a0e-ecc2535d29af",
  });

  const handleClose = (open) => {
    setOpenPersonaModal({
      open: open,
      edit: false,
      persona: {},
    });
  };
  return (
    <>
      {" "}
      <Stack>
        <PageHeader
          left={
            <TitleText
              title={"Personas"}
              subtitle={
                "Personas based on clinical & behavioral patterns of diabetic patients"
              }
            />
          }
          right={
            <IconButton
              onClick={() => {
                setOpenPersonaModal({
                  open: true,
                  edit: false,
                  persona: {},
                });
              }}
              color="primary"
            >
              <AddOutlinedIcon />
            </IconButton>
          }
        />
        {openPersonaModal && (
          <ModalContainer title="New Persona" onClose={handleClose}>
            <PersonaForm SetOpen={handleClose} />
          </ModalContainer>
        )}
        <PersonaListView />
      </Stack>
    </>
  );
};

const DataPointContainer = () => {
  const { openDataModal, setOpenDatapointModal } = useDiseaseArea({
    diseaseUuid: "8ca3b0cd-748f-49a2-8a0e-ecc2535d29af",
  });
  const handleClose = (open) => {
    setOpenDatapointModal({
      open: open,
      edit: false,
      data: {},
    });
  };
  return (
    <>
      <Stack>
        <PageHeader
          left={
            <TitleText
              title={"Data Points"}
              subtitle={
                "Clinical & behavioral data points of diabetic patients"
              }
            />
          }
          right={
            <IconButton
              onClick={() => {
                setOpenDatapointModal({
                  open: true,
                  edit: false,
                  data: {},
                });
              }}
              color="primary"
            >
              <AddOutlinedIcon />
            </IconButton>
          }
        />
        {openDataModal && (
          <ModalContainer title="New Data Points" onClose={handleClose}>
            <DataPointForm SetOpen={handleClose} />
          </ModalContainer>
        )}
        <DatapointListView />
      </Stack>
    </>
  );
};
