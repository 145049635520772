import React from "react";
import {
  AntTab,
  AntTabs,
  Btn,
  PageHeader,
  QuestionCard,
  SelectButton,
  TabPanel,
} from "../../components";
import { NudgeContainer } from "../Nudge/NudgeContainer";
import { PatientContainer } from "../PatientPersonaList/PatientContainer";
import { QuestionBuilder } from "./QuestionBuilder";
import { NudgeContextProvider } from "../../hooks";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { URLS } from "../../constant";
import { NudgeMappingContainer } from "../NudgeMapping/NudgeMappingContainer";
export const QuestionPanel = () => {
  const { diseaseId } = useParams();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  return (
    <>
      <PageHeader
        left={
          <AntTabs value={value} onChange={handleChange}>
            <AntTab label="Builder" {...a11yProps(0)} />
            <AntTab label="Answer Mapping " {...a11yProps(1)} />
            <AntTab label="Nudge Master" {...a11yProps(2)} />
            <AntTab label="Patient List" {...a11yProps(3)} />
          </AntTabs>
        }
        right={
          <Btn
            startIcon={<AddIcon />}
            style={{ textTransform: "capitalize" }}
            variant="text"
            disableElevation
            onClick={() => {
              navigate("/app/" + URLS.IMPORT + "/" + diseaseId);
            }}
          >
            Import Data
          </Btn>
        }
      />
      <TabPanel value={value} index={0}>
        <QuestionBuilder />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NudgeMappingContainer componentId={2} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <NudgeContainer />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PatientContainer />
      </TabPanel>
    </>
  );
};
