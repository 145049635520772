import { Typography } from "@mui/material";
import { colors } from "../../constant";

export const Text = ({
  children,
  size = 10,
  color = colors.ALMOST_BLACK,
  style,
}) => {
  return (
    <>
      <Typography
        style={{
          fontSize: `${size}px`,
          fontFamily: "Noto Sans",
          color: color,
          ...style,
        }}
        component="p"
        variant="body1"
      >
        {children}
      </Typography>
    </>
  );
};
