import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { createContext } from "react";

const QuestionnaireContext = createContext();

export const QuestionnaireContextProvider = ({ children }) => {
  const [questionnaire, setQuestionaire] = React.useState([]);

  const values = React.useMemo(
    () => ({ questionnaire, setQuestionaire }),
    [questionnaire, setQuestionaire]
  );
  return (
    <QuestionnaireContext.Provider value={values}>
      {children}
    </QuestionnaireContext.Provider>
  );
};

const UPDATE_QUESTIONAIRE = gql`
  mutation Mutation($payload: JSON) {
    UpdatedQuestionaires(payload: $payload)
  }
`;
export const useQuestionnaire = () => {
  const GET_DATAPOINT = gql`
    query Query(
      $diseaseUuid: String!
      $datapointType: String
      $source: String
    ) {
      GetAllDataPoints(
        disease_uuid: $diseaseUuid
        datapoint_type: $datapointType
        source: $source
      ) {
        disease_uuid
        uuid
        label
        key
        required
        type
        values
        scores
        min
        max
        next
        id
      }
    }
  `;
  const { questionnaire, setQuestionaire } =
    React.useContext(QuestionnaireContext);
  const useGetDatapoints = useLazyQuery(GET_DATAPOINT);
  const getQuestionnaire = () => {
    return questionnaire;
  };
  const setQuestions = (questionnaires) => {
    setQuestionaire(questionnaires);
  };
  const removeQuestionnaire = (id) => {};

  const useUpdateQuestionare = useMutation(UPDATE_QUESTIONAIRE);
  return {
    getQuestionnaire,
    setQuestions,
    questionnaire,
    useGetDatapoints,
    useUpdateQuestionare,
  };
};
