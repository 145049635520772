import React from "react";
import { DashboardLayout } from "./components/DashboardLayout";
import { MainLayout } from "./components/MainLayout";
import { URLS } from "./constant";
import { AuthCallback, AuthRedirect, AuthWrapper } from "./helpers";

import {
  DiseaseAreaView,
  Landing,
  Login,
  ImportAreaContainer,
  NudgeMappingContainer,
  DictionaryWrapper,
} from "./view";
import { DiseaseContainer } from "./view/Disease/diseaseMaster/DiseaseContainer";
const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      {
        path: URLS.MANAGE_QUESTION + "/:diseaseId",
        element: (
          <AuthWrapper>
            <Landing />
          </AuthWrapper>
        ),
      },
      {
        path: URLS.DISEASE,
        element: (
          <AuthWrapper>
            <DiseaseContainer />
          </AuthWrapper>
        ),
      },
      {
        path: URLS.DISEASE + "/:diseaseId",
        element: (
          <AuthWrapper>
            <DiseaseAreaView />
          </AuthWrapper>
        ),
      },
      {
        path: URLS.IMPORT + "/:diseaseId",
        element: (
          <AuthWrapper>
            <ImportAreaContainer />
          </AuthWrapper>
        ),
      },
      {
        path: URLS.DATA_DICTIONARY + "/:uuid/:diseaseId",
        element: (
          <AuthWrapper>
            <DictionaryWrapper />
          </AuthWrapper>
        ),
      },
      {
        path: URLS.NUDGE_MAPPING,
        element: (
          <AuthWrapper>
            <NudgeMappingContainer />
          </AuthWrapper>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: URLS.LOGIN, element: <Login /> },
      { path: "/", element: <AuthRedirect /> },
      //   { path: "404", element: <NotFound /> },
      //   { path: "/", element: <Navigate to={`app/${URLS.CLIENT}`} /> },
      //   { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/signin-callack/oauth2client",
    element: <AuthCallback />,
  },
];

export default routes;
