import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  ConfirmDialog,
  PageHeader,
  SelectButton,
  Text,
  TitleText,
} from "../../components";
import { colors } from "../../constant";
import { customTableRowColor } from "../../helpers";
import { useNudgeMapping } from "../../hooks";
import { useParams } from "react-router-dom";
export const AnswerMappingListView = ({
  datapoints = [],
  nudges = [],
  personas = [],
  onClickCreateNP,
  setCurrentNudgeMappingData,
  setEditNudgeMapping,
  answerMappings,
  GetNudgeMappingPlan,
}) => {
  const { diseaseId } = useParams();
  const { useGetNudgeMappingPlans, useDeleteNudgeMappingPlan } =
    useNudgeMapping({ disease_uuid: diseaseId });

  const [GetNudgeMappingPlans, { data, loading, error }] =
    useGetNudgeMappingPlans;
  personas = personas?.reduce(
    (obj, item) => Object.assign(obj, { [item.uuid]: item.title }),
    {}
  );
  console.log("xxx answerMappings:---> ", answerMappings);
  datapoints = datapoints.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: `${item.label}` }),
    {}
  );
  // personas = personas.reduce((obj, item) => Object.assign(obj, { [item.uuid]: item.title }), {});
  console.log("xxxpersonas PPP-> ", personas);
  useEffect(() => {
    GetNudgeMappingPlans({
      variables: { type: "DataPoint", disease_id: diseaseId },
    });
  }, [GetNudgeMappingPlans]);
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Question</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> DataPoint Mapped</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Answer Source</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Nudge Linked</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Action</Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            answerMappings &&
            answerMappings.map((row, idx) => (
              <TableRows
                key={idx}
                diseaseId={diseaseId}
                row={row}
                idx={idx}
                personas={personas}
                datapoints={datapoints}
                onClickCreateNP={onClickCreateNP}
                setCurrentNudgeMappingData={setCurrentNudgeMappingData}
                setEditNudgeMapping={setEditNudgeMapping}
                GetNudgeMappingPlan={GetNudgeMappingPlan}
              />
            ))}
        </TableBody>
      </Table>
    </>
  );
};

const TableRows = ({
  row,
  idx,
  diseaseId,
  personas,
  datapoints,
  setCurrentNudgeMappingData,
  setEditNudgeMapping,
  GetNudgeMappingPlan,
}) => {
  const { setNudgeMappingEdit, useDeleteNudgeMappingPlan } = useNudgeMapping({
    disease_uuid: diseaseId,
  });
  const [DeleteNudgeMappingPlan] = useDeleteNudgeMappingPlan;
  const [open, setOpen] = React.useState(false);
  const handleDelete = () => {
    DeleteNudgeMappingPlan({ variables: { key: row.key, type: row.type } });
  };
  const handleClickEdit = (item) => () => {
    const { nudgePlan: { uuid } = {} } = item;
    GetNudgeMappingPlan({
      variables: { uuid },
      onCompleted: (data) => {
        const { GetNudgeMappingPlan: nudgemappingData = {} } = data || {};
        setCurrentNudgeMappingData(nudgemappingData);
        setEditNudgeMapping(true);
      },
      onError: (err) => {
        console.log("xxx error :------>", err);
      },
    });
  };
  return (
    <>
      {open && (
        <ConfirmDialog
          title={"Confirm Delete"}
          discription={"Are you sure once delete can't be undone?"}
          onClose={() => {
            setOpen(false);
          }}
          positive={() => {
            handleDelete();
          }}
          positiveLabel="Yes"
          negativeLabel="No"
        />
      )}
      <TableRow key={row.nudge_id}>
        <TableCell>
          <Text>{row.label}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.key}</Text>
        </TableCell>
        <TableCell component="th" scope="row">
          <Text>{row?.source ? row?.source : "NA"}</Text>
        </TableCell>
        <TableCell>
          <Text>
            <GradingOutlinedIcon fontSize="10" />
            {row?.nudgePlan?.label}
          </Text>
        </TableCell>

        <TableCell>
          {row?.nudgePlan?.label === "Not Linked" ? (
            <></>
          ) : (
            <Button
              onClick={handleClickEdit(row)}
              size="small"
              // variant="text"
            >
              Edit
            </Button>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
