import {
  alpha,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Noto Sans",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const TextBox = ({ label, error, helperText, ...rest }) => {
  return (
    <FormControl fullWidth error={error} variant="standard">
      <InputLabel
        style={{ fontFamily: "Noto Sans", fontSize: "14px", marginTop: "4px" }}
        shrink
        htmlFor="bootstrap-input"
      >
        {label}
      </InputLabel>
      <BootstrapInput {...rest} id="bootstrap-input" />
      {error && (
        <FormHelperText id="component-error-text">{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
