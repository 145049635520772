import { Grid } from "@mui/material";
import React from "react";
import { Btn, QuestionCard } from "../../components";
import { useQuestionnaire, useSnackbar } from "../../hooks";
import { ProcessGrapqlError } from "../../App";

export const QuestionBuilder = () => {
  const { questionnaire, useUpdateQuestionare, setQuestions } =
    useQuestionnaire();
  const [UpdatedQuestionaires] = useUpdateQuestionare;
  const { openSnackBar } = useSnackbar();
  const handleSave = () => {
    const arrlength = questionnaire.length;
    const payload = questionnaire.reduce((arr, row, idx) => {
      if (idx < arrlength - 1) {
        const nextitem = questionnaire?.[idx + 1];
        if (nextitem?.type !== "section") {
          arr.push({ ...row, next: nextitem?.key, start: idx === 0, id: idx });
        } else {
          arr.push({
            ...row,
            start: idx === 0,
            id: idx,
            next: "end",
            end: true,
          });
        }
      } else {
        arr.push({
          ...row,
          start: idx === 0,
          id: idx,
          end: true,
          next: "end",
        });
      }
      return arr;
    }, []);
    console.log(
      "🚀 ~ file: QuestionBuilder.js:31 ~ payload ~ payload:",
      payload
    );

    UpdatedQuestionaires({
      variables: { payload },
      onCompleted: ({ UpdatedQuestionaires = { payload: [] } }) => {
        setQuestions(UpdatedQuestionaires?.payload);
        openSnackBar({ message: "Successfully Save", type: "success" });
      },
      onError: (err) => {
        ProcessGrapqlError({ ...err, snackbar: openSnackBar });
      },
    });
  };
  return (
    <Grid constainer direction="column" spacing={3}>
      <Grid item style={{ marginBottom: "20px" }} xs={12}>
        <Btn
          onClick={() => {
            handleSave();
          }}
          variant="contained"
        >
          Save
        </Btn>
      </Grid>
      {questionnaire &&
        questionnaire.map((row, idx) => (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <QuestionCard item={row} />
          </Grid>
        ))}
    </Grid>
  );
};
