import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { ListItem, Padding, SelectBox } from "../../components";
import { colors } from "../../constant";
import { useQuestionnaire } from "../../hooks";
import { useParams } from "react-router-dom";

export const Questions = () => {
  const { diseaseId } = useParams();
  const { questionnaire, setQuestions, useGetDatapoints } = useQuestionnaire();
  const [dataPointType, setdataPointType] = useState("DataPoint");
  const [source, setSource] = useState("Patient");

  const [GetDatapoint] = useGetDatapoints;
  useEffect(() => {
    GetDatapoint({
      variables: {
        diseaseUuid: diseaseId,
        datapointType: dataPointType,
        source: source,
      },
      onCompleted: ({ GetAllDataPoints = [] }) => {
        setQuestions(GetAllDataPoints);
      },
    });
  }, [GetDatapoint, dataPointType, source]);

  const itemRenderer = (item, index) => {
    return <ListItem data={item} index={index} />;
  };
  const handleReorderChange = (reorderedItems) => {
    setQuestions(reorderedItems);
  };
  return (
    <div style={{ backgroundColor: colors.WHITE }}>
      <Padding>
        <SelectBox
          size={"small"}
          label={"Source"}
          name={"source"}
          placeholder="Source"
          value={source}
          onChange={(e) => {
            setSource(e.target.value);
          }}
          options={[
            { label: "Questionare", value: "Patient" },
            { label: "FollowUp", value: "FollowUp" },
          ]}
        />
        <SelectBox
          label={"Datapoint Type"}
          name={"datapointType"}
          placeholder="Datapoint Type"
          value={dataPointType}
          onChange={(e) => {
            setdataPointType(e.target.value);
          }}
          options={[
            { label: "DataPoint", value: "DataPoint" },
            { label: "Nudge", value: "Nudge" },
          ]}
        />
      </Padding>
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          {questionnaire && (
            <RLDD
              items={questionnaire}
              itemRenderer={itemRenderer}
              onChange={handleReorderChange}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
