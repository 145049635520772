import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { ConfirmDialog, SelectButton, Text } from "../../../components";
import { colors } from "../../../constant";
import { customTableRowColor } from "../../../helpers";
import { useDiseaseArea } from "../../../hooks";
import { useParams } from "react-router-dom";
export const PersonaListView = () => {
  const { diseaseId } = useParams();
  const { useGetPersonas } = useDiseaseArea({ diseaseUuid: diseaseId });

  const [GetPersona, { data, loading, error }] = useGetPersonas;
  useEffect(() => {
    GetPersona({ variables: { diseaseUuid: diseaseId } });
  }, [GetPersona]);
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Persona</Text>
            </TableCell>
            <TableCell>
              <Text color={colors.TEXT_GREY}> Score Range</Text>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            data &&
            data?.GetAllPersonas.map((row, idx) => (
              <TableRows key={idx} diseaseId={diseaseId} row={row} idx={idx} />
            ))}
        </TableBody>
      </Table>
    </>
  );
};

const TableRows = ({ row, idx, diseaseId }) => {
  const { useDeletePersona, setPersona, setPersonaEdit, setOpenPersonaModal } =
    useDiseaseArea({
      diseaseUuid: diseaseId,
    });
  const [DeletePersona] = useDeletePersona;
  const [open, setOpen] = React.useState(false);
  const handleDelete = () => {
    DeletePersona({ variables: { uuid: row.uuid } });
  };
  return (
    <>
      {open && (
        <ConfirmDialog
          title={"Confirm Delete"}
          discription={"Are you sure once delete can't be undone?"}
          onClose={() => {
            setOpen(false);
          }}
          positive={() => {
            handleDelete();
          }}
          positiveLabel="Yes"
          negativeLabel="No"
        />
      )}
      <TableRow
        key={row.uuid}
        style={{ backgroundColor: customTableRowColor(idx) }}
      >
        <TableCell component="th" scope="row">
          <Text> {row.title}</Text>
        </TableCell>
        <TableCell>
          <Text>
            {row.score[0]}-{row.score[1]}
          </Text>
        </TableCell>
        <TableCell>
          <SelectButton
            type="icon"
            onSelect={(item) => {
              switch (item.value) {
                case "delete":
                  {
                    setOpen(true);
                  }
                  break;
                case "edit":
                  {
                    setOpenPersonaModal({
                      open: true,
                      edit: true,
                      persona: row,
                    });
                  }
                  break;
              }
            }}
            options={[
              { label: "Edit", value: "edit" },
              { label: "Delete", value: "delete" },
            ]}
          >
            <MoreVertOutlinedIcon fontSize="8" />
          </SelectButton>
        </TableCell>
      </TableRow>
    </>
  );
};
