import { Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Btn, TextBox, SelectBox } from "../components";
import { useNudge, useSnackbar } from "../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ProcessGrapqlError } from "../App";
import { UserContextProvider, useAuth } from "../hooks";
import {LoginForm} from "./LoginForm"
export const Login = ({  }) => {

  return (
    <>
      <UserContextProvider>
        <LoginForm />
      </UserContextProvider>
    </>
  );
};
