import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ModalContainer, PageHeader, TitleText } from "../../../components";
import { DiseaseForm } from "./DiseaseForm";
import { DiseaseListView } from "./DiseaseListView";
import { DiseaseContextProvider, useDisease } from "../../../hooks";
import { formatFetchDataToLabels } from "../../../helpers";

export const DiseaseContainer = () => {
  return (
    <>
      <DiseaseContextProvider>
        <Stack spacing={3}>
          <DiseaseContainerArea />
        </Stack>
      </DiseaseContextProvider>
    </>
  );
};

const DiseaseContainerArea = () => {
  const { openDiseaseModal, setOpenDiseaseModal } = useDisease({});

  const handleClose = (open) => {
    setOpenDiseaseModal({
      open: open,
      edit: false,
      disease: {},
    });
  };
  return (
    <>
      {" "}
      <Stack>
        <PageHeader
          left={
            <TitleText
              title={"Diseases"}
              subtitle={
                "Diseases based on clinical & behavioral patterns of diabetic patients"
              }
            />
          }
          right={
            <IconButton
              onClick={() => {
                setOpenDiseaseModal({
                  open: true,
                  edit: false,
                  disease: {},
                });
              }}
              color="primary"
            >
              <AddOutlinedIcon />
            </IconButton>
          }
        />
        {openDiseaseModal && (
          <ModalContainer title="New Disease" onClose={handleClose}>
            <DiseaseForm SetOpen={handleClose} />
          </ModalContainer>
        )}
        <DiseaseListView />
      </Stack>
    </>
  );
};
