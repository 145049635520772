import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/public/createUploadLink.js";
import { AlertHelper, StorageHelper } from "./helpers";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "apollo-link-error";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { getAuthUrl, history } from "./helpers";
import { SnackbarContextProvider } from "./hooks";
import { WebSocketLink } from "@apollo/client/link/ws";

const root = ReactDOM.createRoot(document.getElementById("root"));
const mainLink = new createUploadLink({
  uri: "https://drseljv6cpnlb.cloudfront.net",
  // uri: "http://localhost:4000/graphql",
});

const wsLink = new WebSocketLink({
  uri: "wss://drseljv6cpnlb.cloudfront.net",
  // uri: "ws://localhost:4000/graphql",
  options: {
    reconnect: true,
  },
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  mainLink
);
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem("token");
  // const program_data = localStorage.getItem("current-program"); // return the headers to the context so httpLink can read them
  const locale = localStorage.getItem("i18nextLng") || "en";
  //validate acess token localy and if its not valid call refresh token api
  // console.log("xxx decodeAuthToken(token):--->", decodeAuthToken(token));
  // const { isJwtExpired = true } = decodeAuthToken(token);
  // console.log("xxx isJwtExpired:--->", isJwtExpired);
  // if (isJwtExpired) {
  //   const response = await axios.post(`https://api.demohealthlink.in/graphql`, {
  //     query: print(RENEW_TOKEN),
  //     variables: {
  //       token: String(token),
  //     },
  //   });

  //   console.log("xxx response  token1 :-->", response);
  //   const {
  //     renewToken: { payload: { token: new_token = null } = {} } = {},
  //   } = response.data.data;
  //   token = new_token;
  //   console.log("xxx new_token token1 :-->", new_token);
  //   StorageHelper.Set("login", true);
  //   console.log(
  //     "xxx StorageHelper.Getlogin 2nd",
  //     StorageHelper.Get("login")
  //   );
  //   StorageHelper.Set("token", new_token);
  // }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "local-language": locale,
      "content-type": "application/json",
    },
  };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      AlertHelper.error(message);
    });
  if (networkError) console.log(networkError);
});

const client = new ApolloClient({
  uri: "https://drseljv6cpnlb.cloudfront.net",
  link: authLink.concat(errorLink.concat(splitLink)), // <= otherwise will send to this
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

root.render(
  <React.StrictMode>
    <SnackbarContextProvider>
      <BrowserRouter history={history}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </SnackbarContextProvider>
  </React.StrictMode>
);
