import { Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { Btn, SelectBox } from "../../components";
import { DataPointMappingKeys } from "./Constant";
import { useImportArea } from "../../hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const DataPointSelectionForm = ({ setOpen }) => {
  const { diseaseId } = useParams();
  const {
    getExcelDataPoint,
    useAddBulkPatientDataPoint,
    getExcelData,
    useGetDatapoints,
  } = useImportArea();
  const [AddBulkPatientDataPoints, { data, loading, error }] =
    useAddBulkPatientDataPoint();
  const excelDataPoint = getExcelDataPoint();
  const excelData = getExcelData();

  const [
    GetDataPoints,
    {
      data: { GetAllDataPoints } = { GetAllDataPoints: [] },
      loading: dpLoading,
      error: dpError,
    },
  ] = useGetDatapoints;
  useEffect(() => {
    GetDataPoints({
      variables: {
        diseaseUuid: diseaseId,
        datapointType: "DataPoint",
        source: "Import",
      },
    });
  }, [GetDataPoints]);
  const initObj = {};
  DataPointMappingKeys.map((x) => {
    initObj[x] = null;
  });
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  return (
    <>
      <Formik
        initialValues={initObj}
        onSubmit={(values, { setSubmitting }) => {
          AddBulkPatientDataPoints({
            variables: {
              csvData: excelData,
              dpSchema: values,
              diseaseId: diseaseId,
            },
            onCompleted: () => {
              setOpen(false);
            },
          });
        }}
      >
        {({
          isSubmitting,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit} style={{ margin: "5%" }}>
            <Stack spacing={2}>
              <Stack direction={"column"} spacing={1} justifyContent="center">
                <Typography variant="h6">
                  Please select atleast one match before proceeding
                </Typography>
                {!dpLoading && !dpError && (
                  <>
                    <SelectBox
                      key={"patient_id"}
                      label={"Patient ID"}
                      name={"patient_id"}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setButtonDisabled(false);
                        handleChange(e);
                      }}
                      options={excelDataPoint}
                      defaultValue=""
                    />
                    {GetAllDataPoints.map((row, idx) => (
                      <SelectBox
                        key={idx}
                        label={row.key}
                        name={row.key}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setButtonDisabled(false);
                          handleChange(e);
                        }}
                        options={excelDataPoint}
                        defaultValue=""
                      />
                    ))}
                    <SelectBox
                      key={"created_date"}
                      label={"Creation Date"}
                      name={"created_date"}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setButtonDisabled(false);
                        handleChange(e);
                      }}
                      options={excelDataPoint}
                      defaultValue=""
                    />
                  </>
                )}
              </Stack>
              <Stack direction={"row"} spacing={1} justifyContent="center">
                <Btn
                  onClick={() => {
                    setOpen(false);
                  }}
                  variant="outlined"
                  disabled={isSubmitting}
                >
                  Close
                </Btn>
                <Btn
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || isButtonDisabled}
                >
                  Upload
                </Btn>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
