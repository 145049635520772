import { ImportAreaContextProvider } from "../../hooks";
import { ImportAreaWrapper } from "./importAreaWrapper";
export const ImportAreaContainer = () => {
  return (
    <>
      <ImportAreaContextProvider>
        <ImportAreaWrapper />
      </ImportAreaContextProvider>
    </>
  );
};
