import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { Btn, ConfirmDialog, SelectButton, Text } from "../../../components";
import { URLS, colors } from "../../../constant";
import { customTableRowColor } from "../../../helpers";
import { useDisease } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
export const DiseaseListView = () => {
  const { useGetDisease, useDeleteDisease } = useDisease({});

  const [GetDisease, { data, loading, error }] = useGetDisease;
  console.log("xxx data:----> ", data);
  useEffect(() => {
    GetDisease({ variables: {} });
  }, [GetDisease]);
  return (
    <>
      <Paper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Text color={colors.TEXT_GREY}> Name</Text>
              </TableCell>
              <TableCell>
                <Text color={colors.TEXT_GREY}> Description </Text>
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              data &&
              data?.GetAllDiseases.map((row, idx) => (
                <TableRows key={idx} row={row} idx={idx} />
              ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

const TableRows = ({ row, idx }) => {
  const navigate = useNavigate();
  console.log("xxx rows :->", row);
  const { useDeleteDisease, setDisease, setDiseaseEdit, setOpenDiseaseModal } =
    useDisease({
      disease_uuid: row.uuid,
    });
  const [DeleteDisease] = useDeleteDisease;
  const [open, setOpen] = React.useState(false);
  const handleDelete = () => {
    DeleteDisease({ variables: { uuid: row.uuid } });
  };
  return (
    <>
      {open && (
        <ConfirmDialog
          title={"Confirm Delete"}
          discription={"Are you sure once delete can't be undone?"}
          onClose={() => {
            setOpen(false);
          }}
          positive={() => {
            handleDelete();
          }}
          positiveLabel="Yes"
          negativeLabel="No"
        />
      )}
      <TableRow
        key={row.uuid}
        style={{ backgroundColor: customTableRowColor(idx) }}
      >
        <TableCell component="th" scope="row">
          <Text> {row.name}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.description}</Text>
        </TableCell>
        <TableCell>
          <Stack width={10} direction={"row"}>
            <SelectButton
              type="icon"
              onSelect={(item) => {
                switch (item.value) {
                  case "delete":
                    {
                      setOpen(true);
                    }
                    break;
                  case "edit":
                    {
                      setOpenDiseaseModal({
                        open: true,
                        edit: true,
                        disease: row,
                      });
                    }
                    break;
                }
              }}
              options={[
                { label: "Edit", value: "edit" },
                { label: "Delete", value: "delete" },
              ]}
            >
              <MoreVertOutlinedIcon fontSize="8" />
            </SelectButton>
            <Btn
              onClick={() => {
                navigate(`/app/${URLS.DISEASE}/${row.uuid}`);
              }}
            >
              Open
            </Btn>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
