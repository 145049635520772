import { GridPreview } from "./GridPreview";
import { GridLayout, ModalContainer } from "../../components";
import { DataPointSelectionForm } from "./DataPointSelectionForm";
import { useImportArea } from "../../hooks";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";

export const PreviewContainer = (prop) => {
  const { getExcelData, setImportModalOpen } = useImportArea();
  const excelData = getExcelData();
  return (
    <>
      <GridLayout
        leftContent={
          <DataPointSelectionForm
            setOpen={setImportModalOpen}
          ></DataPointSelectionForm>
        }
        rightContent={<GridPreview data={excelData.slice(0, 10)} />}
      />
    </>
  );
};
