import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { createContext } from "react";

const NudgeMappingContext = createContext();

export const NudgeMappingContextProvider = ({ children }) => {
  const [context, setContextData] = React.useState({});
  const setContext = React.useCallback((data) => {
    setContextData((prevState) => ({ ...prevState, ...data }));
  }, []);
  const values = React.useMemo(
    () => ({ context, setContext }),
    [context, setContext]
  );
  return (
    <NudgeMappingContext.Provider value={values}>
      {children}
    </NudgeMappingContext.Provider>
  );
};

export const useNudgeMapping = ({ disease_uuid = "" }) => {
  const { context, setContext } = React.useContext(NudgeMappingContext);

  const useGetNudgePersona = useLazyQuery(GET_ALL_PERSONAS_AND_NUDGES);
  const useGetNudgeMappingPlans = useLazyQuery(GET_ALL_NUDGE_MAPPING_PLAN);
  const useGetNudgeMappingPlan = useLazyQuery(GET_NUDGE_MAPPING_PLAN);
  const useGetDataPoints = useLazyQuery(GET_ALL_DATA_POINTS);
  const useGetAnswerMapping = useLazyQuery(GET_ALL_ANSWER_MAPPING);
  const useGetDataPointNudge = useLazyQuery(GET_DATAPOINT_FOLLOWUP_OPTIONS);
  const useDeleteNudgeMappingPlan = useMutation(DELETE_NUDGE_MAPPING_PLAN, {
    update(cache, { data: { DeleteNudgeMappingPlan = "" } }) {
      const existing = cache.readQuery({
        query: GET_ALL_NUDGE_MAPPING_PLAN,
        variables: { diseaseId: disease_uuid },
      });
      const newData = existing.GetAllNudgeMappingPlan.filter(
        (row) => row.uuid !== DeleteNudgeMappingPlan.uuid
      );
      cache.writeQuery({
        query: GET_ALL_NUDGE_MAPPING_PLAN,
        variables: { diseaseId: disease_uuid },
        data: { GetAllNudgeMappingPlan: newData },
      });
    },
  });

  const setNudgeMappingEdit = React.useCallback((edit) => {
    setContext({ ...context, nudgeMappingEdit: edit });
  }, []);
  const getNudgeMappingEdit = () => {
    return context?.nudgeMappingEdit;
  };

  const useAddNudgeMapping = useMutation(ADD_NUDGE_MAPPING, {
    update(cache, { data: { AddNudgeMappingPlan } }) {
      const existing = cache.readQuery({
        query: GET_ALL_NUDGE_MAPPING_PLAN,
        variables: { diseaseId: disease_uuid },
      });
      const newData = [...existing.GetAllNudgeMappingPlan, AddNudgeMappingPlan];
      cache.writeQuery({
        query: GET_ALL_NUDGE_MAPPING_PLAN,
        variables: { diseaseId: disease_uuid },
        data: { GetAllNudgeMappingPlan: newData },
      });
    },
  });
  const useUpdateNudgeMapping = useMutation(UPDATE_NUDGE_MAPPING, {
    update(cache, { data: { UpdateNudgeMappingPlan } }) {
      const existing = cache.readQuery({
        query: GET_ALL_NUDGE_MAPPING_PLAN,
        variables: { diseaseId: disease_uuid },
      });
      const newData = existing.GetAllNudgeMappingPlan.map((item) =>
        item.uuid !== UpdateNudgeMappingPlan.uuid
          ? item
          : UpdateNudgeMappingPlan
      );
      cache.writeQuery({
        query: GET_ALL_NUDGE_MAPPING_PLAN,
        variables: { diseaseId: disease_uuid },
        data: { GetAllNudgeMappingPlan: newData },
      });
    },
  });
  return {
    useGetNudgePersona,
    useGetNudgeMappingPlans,
    useGetDataPoints,
    useGetAnswerMapping,
    getNudgeMappingEdit,
    setNudgeMappingEdit,
    useAddNudgeMapping,
    useDeleteNudgeMappingPlan,
    useUpdateNudgeMapping,
    useGetNudgeMappingPlan,
    useGetDataPointNudge,
  };
};
const GET_ALL_NUDGE_MAPPING_PLAN = gql`
  query GetAllNudgeMappingPlan($diseaseId: String!) {
    GetAllNudgeMappingPlan(disease_id: $diseaseId) {
      uuid
      key
      label
      persona
      nudge_plan {
        action
        value
        nudges
        type
      }
      disease_id
    }
  }
`;
const GET_NUDGE_MAPPING_PLAN = gql`
  query GetNudgeMappingPlan($uuid: String!) {
    GetNudgeMappingPlan(uuid: $uuid) {
      uuid
      key
      label
      persona
      nudge_plan {
        action
        value
        nudges
      }
      disease_id
    }
  }
`;
const GET_ALL_DATA_POINTS = gql`
  query Query($disease_uuid: String!) {
    GetAllDataPoints(disease_uuid: $disease_uuid) {
      disease_uuid
      uuid
      label
      key
      required
      type
      values
      scores
      min
      max
      next
      id
    }
  }
`;
const GET_ALL_ANSWER_MAPPING = gql`
  query Query($disease_uuid: String!) {
    GetAnswerMapping(disease_uuid: $disease_uuid) {
      disease_uuid
      uuid
      label
      key
      required
      type
      values
      scores
      min
      max
      next
      id
      source
      nudgePlan {
        uuid
        label
      }
    }
  }
`;
const GET_ALL_PERSONAS_AND_NUDGES = gql`
  query Query($diseaseUuid: String!) {
    GetAllNudges(disease_uuid: $diseaseUuid) {
      nudge
      nudge_id
    }
    GetAllPersonas(disease_uuid: $diseaseUuid) {
      title
      uuid
    }
  }
`;
const GET_DATAPOINT_FOLLOWUP_OPTIONS = gql`
  query Query(
    $diseaseUuid: String!
    $datapointType: String
    $source: String
    $type: String
  ) {
    DataPointFollowOption: GetAllDataPoints(
      disease_uuid: $diseaseUuid
      datapoint_type: $datapointType
      source: $source
      type: $type
    ) {
      label: label
      value: uuid
    }
  }
`;
const ADD_NUDGE_MAPPING = gql`
  mutation Mutation(
    $key: String!
    $diseaseId: String!
    $label: String
    $persona: String
    $nudgePlan: [NudgePlanInput]
  ) {
    AddNudgeMappingPlan(
      key: $key
      disease_id: $diseaseId
      label: $label
      persona: $persona
      nudge_plan: $nudgePlan
    ) {
      uuid
      key
      label
      persona
      nudge_plan {
        action
        nudges
        value
        type
      }
      disease_id
    }
  }
`;
const UPDATE_NUDGE_MAPPING = gql`
  mutation UpdateNudgeMappingPlan(
    $key: String!
    $uuid: String!
    $diseaseId: String!
    $label: String
    $persona: String
    $nudgePlan: [NudgePlanInput]
  ) {
    UpdateNudgeMappingPlan(
      key: $key
      uuid: $uuid
      disease_id: $diseaseId
      label: $label
      persona: $persona
      nudge_plan: $nudgePlan
    ) {
      uuid
      key
      label
      persona
      nudge_plan {
        action
        value
        type
        nudges
      }
      disease_id
    }
  }
`;
const DELETE_NUDGE_MAPPING_PLAN = gql`
  mutation DeleteNudgeMappingPlan($uuid: String!) {
    DeleteNudgeMappingPlan(uuid: $uuid){
      uuid
    }
  }
`;
