import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React from "react";

export const useDiseaseList = ({}) => {
  const useGetDiseaseList = useLazyQuery(GET_DISEASE);
  const useGetDisease = useLazyQuery(GET_DISEASE_DETAILS);

  return {
    useGetDiseaseList,
    useGetDisease
  };
};
const GET_DISEASE = gql`
  query Query {
    GetAllDiseases {
      name
      uuid
      description
      createdOn
      lastUpdatedOn
    }
  }
`;
const GET_DISEASE_DETAILS = gql`
  query Query($uuid: String!) {
    GetDisease(uuid: $uuid) {
      name
      uuid
      description
      createdOn
      lastUpdatedOn
    }
  }
`;
