import React, { createContext } from "react";

const SnackbarContext = createContext();
export const SnackbarContextProvider = ({ children }) => {
  const [context, setContextData] = React.useState({});
  const setContext = React.useCallback((data) => {
    setContextData(data);
  }, []);
  const values = React.useMemo(
    () => ({ context, setContext }),
    [context, setContext]
  );

  return (
    <SnackbarContext.Provider value={values}>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { context, setContext } = React.useContext(SnackbarContext);

  const openSnackBar = React.useCallback(
    ({ message = "", type = "success" }) => {
      setContext({ ...context, message: message, type: type, open: true });
    },
    []
  );
  const closeSnackBar = React.useCallback(() => {
    setContext({ open: false });
  }, []);

  return { openSnackBar, context, closeSnackBar };
};
