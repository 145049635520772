import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Text } from "../../components/global/Text";
import { customTableRowColor } from "../../helpers";
export const GridPreview = ({ data = [], keys = [] }) => {
  if (keys.length == 0) {
    keys = data[0] && Object.keys(data[0]);
  }
  return (
    <>
      {keys && data && (
        <Table>
          <TableHead>
            <TableRow>
              {keys.map((key, id) => (
                <TableCell key={id}>
                  <Text>{key}</Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, id) => (
              <TableRow
                key={id}
                style={{ backgroundColor: customTableRowColor(id) }}
              >
                {keys.map((attr, idx) => (
                  <TableCell key={idx}>
                    <Text>{row[attr]}</Text>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
