import { IconButton, Stack } from "@mui/material";
import { useEffect } from "react";
import { PageHeader, TitleText } from "../../components";
import { PatientPersonaContextProvider, usePatientPersona } from "../../hooks";
import { PatientPersonaListView } from "./PatientPersonaListView";

export const PatientContainer = () => {
  return (
    <>
      <PatientPersonaContextProvider>
        <Stack spacing={3}>
          <NudgeContainerArea />
        </Stack>
      </PatientPersonaContextProvider>
    </>
  );
};

const NudgeContainerArea = () => {
  const { useGetPatientPersonaList } = usePatientPersona({
    diseaseUuid: "",
  });

  const [
    GetPatientPersonaList,
    { data: { GetPatientPersonaListByDiseaseId: GetAllPatients = [] } = {} },
  ] = useGetPatientPersonaList;

  console.log("xxx modules:--->", GetAllPatients);

  useEffect(() => {
    GetPatientPersonaList({ variables: {} });
  }, [GetPatientPersonaList]);

  const handleClose = (open) => {
    // setOpenNudgeModal({
    //   open: open,
    //   edit: false,
    //   nudge: {},
    // });
  };
  return (
    <>
      {" "}
      <Stack>
        <PageHeader
          left={<TitleText title={"Patients"} subtitle={"List of patients"} />}
          right={
            <IconButton
              onClick={() => {
                // setOpenNudgeModal({
                //   open: true,
                //   edit: false,
                //   nudge: {},
                // });
              }}
              color="primary"
            >
              {/* <AddOutlinedIcon /> */}
            </IconButton>
          }
        />

        <PatientPersonaListView />
      </Stack>
    </>
  );
};
